import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ITourBooking,
  ITourBookingInitialState,
  ITourBookingPlace,
  ITourList,
} from "./tourBookingModel";
import {
  createTourBooking,
  createTouristInfo,
  deleteTourBooking,
  deleteTourBookingItinerary,
  deleteTouristDocument,
  getAllTourBookings,
  getByIDTourBookingStep1,
  getByIDTourBookingStep2,
  getByIDTourBookingStep3,
  getCheckAirlineSeats,
  getGetPaxTourCostWithName,
  getMonthWiseTourDatesByTourID,
  getTourBookingCancellationViewByID,
  getTourBookingItinerariesByTourBookingID,
  getTourBySectorIDAndTravelType,
  getTourCost,
  getTourPlacesByTourID,
  insertTourBookingItinerary,
  insertTourBookingStep2,
  insertTourBookingStep3,
  tourBookingCancellation,
  updateTourBooking,
  updateTourBookingItinerary,
  uploadTourBookingTouriestDoc,
} from "./services/tourBooking.services";

const initialState: ITourBookingInitialState = {
  loading: false,
  error: "",
  TourBookingList: [],
  TourDateCodeDetails: {},
  TourBookingStep2Detail: {
    ID: null,
    RoomPaxes: [],
  },
  TourBookingStep3Detail: {
    TourBookingID: null,
    TourPlaceBooking: [],
  },
  TouristPaxInfo: [],
  TourBookingID: null,
  ActiveStep: 0,
  deleteIndex: -1,
  docdeleteIndex: -1,
  TourBookingStep1Detail: {
    SectorID: null,
    TourID: null,
    TourCategoryID: null,
    CompanyID: null,
    TravelType: "",
    TourMonth: "",
    TourDate: "",
    TourStartDate: "",
    NoOfNights: null,
    TourEndDate: "",
    FirstName: "",
    LastName: "",
    MobileNo: "",
    EmailID: "",
    Address: "",
    CityID: null,
    StateID: null,
    CountryID: null,
    TourCode: "",
    IsCompanyInvoice: false,
    CompanyName: "",
    CompanyAddress: "",
    CompanyStateID: null,
    CompanyCountryID: null,
    CompanyGSTNo: "",
    CompanyPANNo: "",
    EmergencyNo: "",
    IsGST: false,
    IsTCS: false,
    TotalAmount: 0,
    TotalCost: 0,
    DiscountType: 1,
    DiscountValue: 0,
    DiscountAmount: null,
    AdminDiscountAmount: 0,
    TotalDiscountAmount: null,
    TotalTCSAmount: 0,
    TotalGSTAmount: 0,
    TotalExtraCost: 0,
    Kasar: 0,
    PaxTypeList: [],
    SightseenDetails: [],
    FoodOptionID: null,
    Remarks: "",
    CityName: "",
  },
  TourBookingCostDetails: {
    TotalAmount: null,
    SectorType: "",
    TwinSharing: null,
    ThreeSharingRate: null,
    ExtraAdultRate: null,
    CNBRate: null,
    CWBRate: null,
    InfantRate: null,
  },
  TourBookingPlaces: [],
  TourBookingDataListDetails: {
    NoOfNights: null,
    GSTPer: 0,
    TourCode: "",
    SectorType: "",
    TCSPer: 0,
    IsApplyOnServiceTax: false,
    MonthData: [],
    PlaceData: [],
    TourCategoryData: [],
    NightData: [],
  },
  TourList: [],
  TourBookingItineraryID: null,
  TourBookingItineraryDetails: {
    // TourID: null,
    TourBookingID: null,
    DayNo: null,
    ItineraryTime: "",
    ItineraryID: null,
    Position: null,
  },
  TourBookingItineraryList: [],
  TourBookingCancellationDetails: {},
};

const tourBookingSlice = createSlice({
  name: "tourBooking",
  initialState,
  reducers: {
    setTourBookingID: (state, action) => {
      state.TourBookingID = action.payload;
    },
    clearTourBookingID: (state) => {
      state.TourBookingID = initialState.TourBookingID;
    },
    clearTourBookingStep1Details: (state) => {
      state.TourBookingStep1Detail = initialState.TourBookingStep1Detail;
    },
    clearTourBookingStep2Details: (state) => {
      state.TourBookingStep2Detail = initialState.TourBookingStep2Detail;
    },
    clearTourBookingStep3Details: (state) => {
      state.TourBookingStep3Detail = initialState.TourBookingStep3Detail;
    },
    clearTourBookingCostDetails: (state) => {
      state.TourBookingCostDetails = initialState.TourBookingCostDetails;
    },
    setTourBookingActiveStep: (state, action) => {
      state.ActiveStep = action.payload;
    },
    clearTourBookingActiveStep: (state) => {
      state.ActiveStep = initialState.ActiveStep;
    },
    increaseTourBookingActiveStep: (state) => {
      state.ActiveStep = state.ActiveStep + 1;
    },
    decreaseTourBookingActiveStep: (state) => {
      state.ActiveStep = state.ActiveStep - 1;
    },
    setTourBookingDeleteIndex: (state, action) => {
      state.deleteIndex = action.payload;
    },
    setTourTouristDocDeleteIndex: (state, action) => {
      state.docdeleteIndex = action.payload;
    },
    clearTourTouristDocDeleteIndex: (state) => {
      state.docdeleteIndex = initialState.docdeleteIndex;
    },
    setTourBookingItineraryID: (state, action) => {
      state.TourBookingItineraryID = action.payload;
    },
    clearTourBookingItineraryID: (state) => {
      state.TourBookingItineraryID = initialState.TourBookingItineraryID;
    },
    setTourBookingItineraryDetails: (state, action) => {
      state.TourBookingItineraryDetails = action.payload;
    },
    clearTourBookingItineraryDetails: (state) => {
      state.TourBookingItineraryDetails =
        initialState.TourBookingItineraryDetails;
    },
    clearTourBookingItineraryList: (state) => {
      state.TourBookingItineraryList = initialState.TourBookingItineraryList;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTourBookings.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllTourBookings.fulfilled,
      (state, action: PayloadAction<ITourBooking[]>) => {
        state.loading = false;
        state.TourBookingList = action.payload || [];
      }
    );
    builder.addCase(getAllTourBookings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TourBookingList = [];
    });
    builder.addCase(createTourBooking.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createTourBooking.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createTourBooking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(insertTourBookingStep3.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(insertTourBookingStep3.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(insertTourBookingStep3.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(insertTourBookingStep2.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(insertTourBookingStep2.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(insertTourBookingStep2.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(uploadTourBookingTouriestDoc.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(uploadTourBookingTouriestDoc.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(uploadTourBookingTouriestDoc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(createTouristInfo.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createTouristInfo.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createTouristInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateTourBooking.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateTourBooking.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTourBooking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTourBooking.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTourBooking.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTourBooking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTouristDocument.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTouristDocument.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTouristDocument.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getByIDTourBookingStep1.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getByIDTourBookingStep1.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TourBookingStep1Detail = action.payload;
    });
    builder.addCase(getByIDTourBookingStep1.rejected, (state, action) => {
      state.loading = false;
      state.TourBookingStep1Detail = initialState.TourBookingStep1Detail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getByIDTourBookingStep2.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getByIDTourBookingStep2.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TourBookingStep2Detail = {
        ...state.TourBookingStep2Detail,
        ...action.payload,
      };
    });
    builder.addCase(getByIDTourBookingStep2.rejected, (state, action) => {
      state.loading = false;
      state.TourBookingStep2Detail = initialState.TourBookingStep2Detail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getByIDTourBookingStep3.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getByIDTourBookingStep3.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TourBookingStep3Detail = {
        ...state.TourBookingStep3Detail,
        ...action.payload,
      };
    });
    builder.addCase(getByIDTourBookingStep3.rejected, (state, action) => {
      state.loading = false;
      state.TourBookingStep3Detail = initialState.TourBookingStep3Detail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getCheckAirlineSeats.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getCheckAirlineSeats.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TourDateCodeDetails = action.payload;
    });
    builder.addCase(getCheckAirlineSeats.rejected, (state, action) => {
      state.loading = false;
      state.TourDateCodeDetails = initialState.TourDateCodeDetails;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourCost.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getTourCost.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TourBookingCostDetails = action.payload;
    });
    builder.addCase(getTourCost.rejected, (state, action) => {
      state.loading = false;
      state.TourBookingCostDetails = initialState.TourBookingCostDetails;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourPlacesByTourID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getTourPlacesByTourID.fulfilled,
      (state, action: PayloadAction<ITourBookingPlace[]>) => {
        state.loading = false;
        state.TourBookingPlaces = action.payload || [];
      }
    );
    builder.addCase(getTourPlacesByTourID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TourBookingPlaces = [];
    });
    builder.addCase(getMonthWiseTourDatesByTourID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getMonthWiseTourDatesByTourID.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.TourBookingDataListDetails = action.payload;
      }
    );
    builder.addCase(getMonthWiseTourDatesByTourID.rejected, (state, action) => {
      state.loading = false;
      state.TourBookingDataListDetails =
        initialState.TourBookingDataListDetails;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getGetPaxTourCostWithName.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getGetPaxTourCostWithName.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.TouristPaxInfo = action.payload;
      }
    );
    builder.addCase(getGetPaxTourCostWithName.rejected, (state, action) => {
      state.loading = false;
      state.TouristPaxInfo = initialState.TouristPaxInfo;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourBySectorIDAndTravelType.pending, (state) => {
      // state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getTourBySectorIDAndTravelType.fulfilled,
      (state, action: PayloadAction<ITourList[]>) => {
        state.loading = false;
        state.TourList = action.payload || [];
      }
    );
    builder.addCase(
      getTourBySectorIDAndTravelType.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.TourList = [];
      }
    );

    builder.addCase(insertTourBookingItinerary.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(insertTourBookingItinerary.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(insertTourBookingItinerary.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateTourBookingItinerary.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateTourBookingItinerary.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTourBookingItinerary.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTourBookingItinerary.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTourBookingItinerary.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTourBookingItinerary.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(
      getTourBookingItinerariesByTourBookingID.pending,
      (state) => {
        state.loading = true;
        state.error = "";
      }
    );
    builder.addCase(
      getTourBookingItinerariesByTourBookingID.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.TourBookingItineraryList = action.payload;
      }
    );
    builder.addCase(
      getTourBookingItinerariesByTourBookingID.rejected,
      (state, action) => {
        state.loading = false;
        state.TourBookingItineraryList = initialState.TourBookingItineraryList;
        state.error = action.error.message || "Something went wrong";
      }
    );
    builder.addCase(getTourBookingCancellationViewByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getTourBookingCancellationViewByID.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.TourBookingCancellationDetails = action.payload;
      }
    );
    builder.addCase(
      getTourBookingCancellationViewByID.rejected,
      (state, action) => {
        state.loading = false;
        state.TourBookingCancellationDetails =
          initialState.TourBookingCancellationDetails;
        state.error = action.error.message || "Something went wrong";
      }
    );
    builder.addCase(tourBookingCancellation.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(tourBookingCancellation.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(tourBookingCancellation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  setTourBookingID,
  clearTourBookingID,
  clearTourBookingStep1Details,
  clearTourBookingStep2Details,
  clearTourBookingStep3Details,
  clearTourBookingCostDetails,
  setTourBookingActiveStep,
  clearTourBookingActiveStep,
  increaseTourBookingActiveStep,
  decreaseTourBookingActiveStep,
  setTourBookingDeleteIndex,
  setTourTouristDocDeleteIndex,
  clearTourTouristDocDeleteIndex,
  setTourBookingItineraryID,
  clearTourBookingItineraryID,
  clearTourBookingItineraryList,
  setTourBookingItineraryDetails,
  clearTourBookingItineraryDetails,
} = tourBookingSlice.actions;
export default tourBookingSlice.reducer;
