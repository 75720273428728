import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import RippleButton from "../../../components/common/RippleButton";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { LoadingPanel } from "../../../components/layout/Loading";
import {
  createTourPlaceArray,
  getActiveTourDatesByTourID,
  getAllActiveTourPlacesByTourID,
} from "../services/tour.services";
import TourPlaceArray from "./TourPlaceArray";
import {
  clearTourPlaceDetails,
  decreaseActiveStep,
  increaseActiveStep,
} from "../tourSlice";
import { closeDialog } from "../../../components/dialog/dialogSlice";
import { getCityBySectorID } from "../../city/services/city.services";
import ShadowCard from "../../../components/common/ShadowCard";
import { getAllActiveHotels } from "../../hotel/services/hotel.services";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const TourPlaceChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const TourPlaceDetails = useAppSelector(
    (state) => state.tour.TourPlaceDetails
  );

  useEffect(() => {
    const tourPlacesCopy = JSON.parse(JSON.stringify(TourPlaceDetails));
    formRenderProps.onChange("TourPlaces", {
      value: tourPlacesCopy || [],
    });
  }, [TourPlaceDetails]);
  return null;
};

const TourPlaces: React.FC = () => {
  const location = useLocation();
  const TourID = location.state?.TourID;
  const SectorID = location.state?.SectorID;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.tour.loading);
  const TourPlaceDetails = useAppSelector(
    (state) => state.tour.TourPlaceDetails
  );

  useEffect(() => {
    dispatch(getAllActiveHotels());
    if (TourID) {
      dispatch(getAllActiveTourPlacesByTourID(TourID));
      dispatch(getActiveTourDatesByTourID(TourID));
    }
    if (SectorID) {
      dispatch(getCityBySectorID(SectorID));
    }

    return () => {
      dispatch(closeDialog());
      dispatch(clearTourPlaceDetails());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    if (TourID) {
      const insertPayload = {
        TourPlaces: values?.TourPlaces
          ? values?.TourPlaces.map((tourPlace: any) => {
              return {
                TourID: TourID,
                ID: tourPlace?.ID ? +tourPlace?.ID : 0,
                CityID: tourPlace?.CityID ? +tourPlace?.CityID : null,
                Nights: tourPlace?.Nights ? +tourPlace?.Nights : null,
                Position: tourPlace?.Position ? +tourPlace?.Position : null,
                HotelID: tourPlace?.HotelID ? +tourPlace?.HotelID : null,
                TourCodeID: Array.isArray(tourPlace?.TourCodeID)
                  ? tourPlace?.TourCodeID.join(",")
                  : tourPlace?.TourCodeID,
              };
            })
          : [],
      };
      const response = await dispatch(createTourPlaceArray(insertPayload));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(increaseActiveStep());
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={TourPlaceDetails}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <TourPlaceChangeWatcher formRenderProps={formRenderProps} />
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem style={{ marginTop: 10 }} colSpan={3}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={TourPlaceArray}
                    name="TourPlaces"
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <RippleButton
                    type="button"
                    fillMode="outline"
                    themeColor="primary"
                    style={{ marginRight: 4 }}
                    onClick={() => dispatch(decreaseActiveStep())}
                  >
                    Previous
                  </RippleButton>
                  <ButtonWithLoading
                    label={"Save & Next"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default TourPlaces;
