import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IBank } from "../bankModel";

export const getAllBankes = createAsyncThunk("Bank/FindAllBank", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/Bank/FindAllBank`,
      {
        BankName: "",
      }
    );
    return response.data?.Data;
  } catch (error: any) {
    // ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching Bankes:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createBank = createAsyncThunk(
  "Bank/InsertBank",
  async (BankData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Bank/InsertBank`,
        BankData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating bank:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateBank = createAsyncThunk(
  "Bank/UpdateBank",
  async (BankData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Bank/UpdateBank`,
        BankData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating bank :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteBank = createAsyncThunk(
  "Bank/DeleteBank",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Bank/DeleteBank`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting bank :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getBankByID = createAsyncThunk(
  "Bank/FindByIDBank",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Bank/FindByIDBank`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        CompanyID: result.CompanyID,
        CompanyName: result.CompanyName,
        BankName: result.BankName,
        Branch: result.Branch,
        Address: result.Address,
        AccountNo: result.AccountNo,
        IFSCCode: result.IFSCCode,
        QRCodeImage: result.QRCodeImage,
        CancelledChequeImage: result.CancelledChequeImage,
        IsActive: result.IsActive ? 1 : 2,
      } as IBank;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching bank :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveBankes = createAsyncThunk(
  "Bank/FindAllActiveBank",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Bank/FindAllActiveBank`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Bankes:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveBank = createAsyncThunk(
  "Bank/ActiveInActiveBank",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Bank/ActiveInActiveBank`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive bank :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

// IMAGE UPLOAD

export const BankDetailsImageUpload = createAsyncThunk(
  "Bank/BankDetailsImageUpload",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Bank/BankDetailsImageUpload`,
        formData,
        {
          headers: { "content-type": "multipart/form-data" },
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error upload Bank Image :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
