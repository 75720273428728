import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDocument, IDocumentInitialState } from "./documentModel";
import {
  getAllDocument,
  createDocument,
  updateDocument,
  deleteDocument,
  getDocumentByID,
  getAllActiveDocument,
  ActiveInactiveDocument,
} from "./services/document.services";

const initialState: IDocumentInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  DocumentList: [],
  DocumentID: null,
  DocumentDetail: {
    ID: null,
    DocumentName: "",
    Remarks: "",
    IsActive: 1,
  },
};

const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    clearDocumentDetails: (state) => {
      state.DocumentDetail = initialState.DocumentDetail;
    },
    setDocumentID: (state, action) => {
      state.DocumentID = action.payload;
    },
    clearDocumentID: (state) => {
      state.DocumentID = initialState.DocumentID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDocument.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllDocument.fulfilled,
      (state, action: PayloadAction<IDocument[]>) => {
        state.loading = false;
        state.DocumentList = action.payload || [];
      }
    );
    builder.addCase(getAllDocument.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DocumentList = [];
    });
    builder.addCase(createDocument.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createDocument.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createDocument.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateDocument.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateDocument.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateDocument.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteDocument.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteDocument.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteDocument.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getDocumentByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getDocumentByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.DocumentDetail = action.payload;
    });
    builder.addCase(getDocumentByID.rejected, (state, action) => {
      state.formLoading = false;
      state.DocumentDetail = initialState.DocumentDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveDocument.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveDocument.fulfilled,
      (state, action: PayloadAction<IDocument[]>) => {
        state.loading = false;
        state.DocumentList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveDocument.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DocumentList = [];
    });
    builder.addCase(ActiveInactiveDocument.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveDocument.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveDocument.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearDocumentDetails, clearDocumentID, setDocumentID } =
  documentSlice.actions;
export default documentSlice.reducer;
