import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createState,
  deleteState,
  getAllActiveStates,
  getAllStates,
  getStateByID,
  stateActiveInactive,
  updateState,
} from "./services/state.services";
import { IState, IStateInitialstate } from "./stateModel";

const initialState: IStateInitialstate = {
  loading: false,
  formLoading: false,
  error: "",
  StateList: [],
  StateID: null,
  StateDetail: {
    CountryID: null,
    StateName: "",
    IsActive: 1,
  },
};

const stateSlice = createSlice({
  name: "state",
  initialState,
  reducers: {
    clearStateDetails: (state) => {
      state.StateDetail = initialState.StateDetail;
    },
    setStateID: (state, action) => {
      state.StateID = action.payload;
    },
    clearStateID: (state) => {
      state.StateID = initialState.StateID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllStates.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllStates.fulfilled,
      (state, action: PayloadAction<IState[]>) => {
        state.loading = false;
        state.StateList = action.payload || [];
      }
    );
    builder.addCase(getAllStates.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.StateList = [];
    });
    builder.addCase(createState.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createState.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createState.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateState.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateState.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateState.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteState.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteState.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteState.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getStateByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getStateByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.StateDetail = action.payload;
    });
    builder.addCase(getStateByID.rejected, (state, action) => {
      state.formLoading = false;
      state.StateDetail = initialState.StateDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveStates.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveStates.fulfilled,
      (state, action: PayloadAction<IState[]>) => {
        state.loading = false;
        state.StateList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveStates.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.StateList = [];
    });
    builder.addCase(stateActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(stateActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(stateActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearStateDetails, setStateID, clearStateID } =
  stateSlice.actions;
export default stateSlice.reducer;
