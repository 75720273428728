import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  cityActiveInactive,
  createCity,
  deleteCity,
  getAllActiveCities,
  getAllCities,
  getCityByID,
  getCityBySectorID,
  getCityByTourID,
  updateCity,
} from "./services/city.services";
import { ICity, ICityInitialState, ISectorCity, ITourCity } from "./cityModel";

const initialState: ICityInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  CityList: [],
  TourCityList: [],
  SectorCityList: [],
  CityID: null,
  CityDetail: {
    CountryID: null,
    StateID: null,
    CityName: "",
    IsActive: 1,
  },
};

const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {
    clearCityDetails: (state) => {
      state.CityDetail = initialState.CityDetail;
    },
    setCityID: (state, action) => {
      state.CityID = action.payload;
    },
    clearCityID: (state) => {
      state.CityID = initialState.CityID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCities.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllCities.fulfilled,
      (state, action: PayloadAction<ICity[]>) => {
        state.loading = false;
        state.CityList = action.payload || [];
      }
    );
    builder.addCase(getAllCities.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CityList = [];
    });
    builder.addCase(createCity.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createCity.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createCity.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateCity.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateCity.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateCity.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteCity.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteCity.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCity.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getCityByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getCityByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.CityDetail = action.payload;
    });
    builder.addCase(getCityByID.rejected, (state, action) => {
      state.formLoading = false;
      state.CityDetail = initialState.CityDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveCities.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveCities.fulfilled,
      (state, action: PayloadAction<ICity[]>) => {
        state.loading = false;
        state.CityList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveCities.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CityList = [];
    });
    builder.addCase(cityActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(cityActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(cityActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getCityBySectorID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getCityBySectorID.fulfilled,
      (state, action: PayloadAction<ISectorCity[]>) => {
        state.loading = false;
        state.SectorCityList = action.payload;
      }
    );
    builder.addCase(getCityBySectorID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SectorCityList = [];
    });
    builder.addCase(getCityByTourID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getCityByTourID.fulfilled,
      (state, action: PayloadAction<ITourCity[]>) => {
        state.loading = false;
        state.TourCityList = action.payload;
      }
    );
    builder.addCase(getCityByTourID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TourCityList = [];
    });
  },
});

export const { clearCityDetails, setCityID, clearCityID } = citySlice.actions;
export default citySlice.reducer;
