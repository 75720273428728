import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ISector } from "../sectorModel";

export const getAllSector = createAsyncThunk(
  "Sector/FindAllSector",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sector/FindAllSector`,
        {
          SectorName: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sector:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createSector = createAsyncThunk(
  "Sector/InsertSector",
  async (SectorData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sector/InsertSector`,
        SectorData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating bendors:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateSector = createAsyncThunk(
  "Sector/UpdateSector",
  async (SectorData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sector/UpdateSector`,
        SectorData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating bendors :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteSector = createAsyncThunk(
  "Sector/DeleteSector",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sector/DeleteSector`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting bendors :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSectorByID = createAsyncThunk(
  "Sector/FindByIDSector",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sector/FindByIDSector`,
        { ID: ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result?.ID ?? null,
        RegionID: result?.RegionID ?? null,
        RegionName: result?.RegionName ?? "",
        CityID: result.CityID
          ? result.CityID?.split(",")?.map((e: string) => +e)
          : [],
        CityName: result?.CityName ?? "",
        SectorType: result?.SectorType ?? "",
        SectorName: result?.SectorName ?? "",
        Prefix: result?.Prefix ?? "",
        VisaAmount: result?.VisaAmount ?? null,
        InsuranceAmount: result?.InsuranceAmount ?? null,
        Color: result?.Color ?? null,
        DocumentDetails: result?.DocumentDetails ?? null,
        IsActive: result?.IsActive ? 1 : 2,
      } as ISector;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching bendors :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveSector = createAsyncThunk(
  "Sector/FindAllActiveSector",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Sector/FindAllActiveSector`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sector:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveSector = createAsyncThunk(
  "Sector/ActiveInActiveSector",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sector/ActiveInActiveSector`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive bendors :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
