import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPaxType, IPaxTypeInitialState } from "./paxTypeModel";
import {
  ActiveInactivePaxType,
  createPaxType,
  deletePaxType,
  getAllActivePaxTypes,
  getAllPaxTypes,
  getPaxTypeByID,
  updatePaxType,
} from "./services/paxType.services";

const initialState: IPaxTypeInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  PaxTypeID: null,
  PaxTypeList: [],
  PaxTypeDetail: {
    PaxType: "",
    PaxCount: null,
    Adult: null,
    ExtraAdult: null,
    CWB: null,
    CNB: null,
    Infant: null,
    IsActive: 1,
  },
};

const paxTypeSlice = createSlice({
  name: "paxType",
  initialState,
  reducers: {
    clearPaxTypeDetails: (state) => {
      state.PaxTypeDetail = initialState.PaxTypeDetail;
    },
    setPaxTypeID: (state, action) => {
      state.PaxTypeID = action.payload;
    },
    clearPaxTypeID: (state) => {
      state.PaxTypeID = initialState.PaxTypeID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPaxTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllPaxTypes.fulfilled,
      (state, action: PayloadAction<IPaxType[]>) => {
        state.loading = false;
        state.PaxTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllPaxTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PaxTypeList = [];
    });
    builder.addCase(createPaxType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createPaxType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createPaxType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updatePaxType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updatePaxType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updatePaxType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deletePaxType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deletePaxType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePaxType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getPaxTypeByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getPaxTypeByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.PaxTypeDetail = action.payload;
    });
    builder.addCase(getPaxTypeByID.rejected, (state, action) => {
      state.formLoading = false;
      state.PaxTypeDetail = initialState.PaxTypeDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActivePaxTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActivePaxTypes.fulfilled,
      (state, action: PayloadAction<IPaxType[]>) => {
        state.loading = false;
        state.PaxTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllActivePaxTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PaxTypeList = [];
    });
    builder.addCase(ActiveInactivePaxType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactivePaxType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactivePaxType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearPaxTypeDetails, clearPaxTypeID, setPaxTypeID } =
  paxTypeSlice.actions;
export default paxTypeSlice.reducer;
