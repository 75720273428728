import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGST, IGSTInitialState } from "./gstModel";
import {
  ActiveInactiveGST,
  createGST,
  deleteGST,
  getAllActiveGST,
  getAllGST,
  getGSTByID,
  updateGST,
} from "./services/gst.services";

const initialState: IGSTInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  GSTList: [],
  GSTID: null,
  GSTDetail: {
    Title: "",
    GSTPer: null,
    CGSTPer: null,
    SGSTPer: null,
    IsApplyOnServiceTax: false,
    IsActive: 1,
  },
};

const gstSlice = createSlice({
  name: "gst",
  initialState,
  reducers: {
    clearGSTDetails: (state) => {
      state.GSTDetail = initialState.GSTDetail;
    },
    setGSTID: (state, action) => {
      state.GSTID = action.payload;
    },
    clearGSTID: (state) => {
      state.GSTID = initialState.GSTID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllGST.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllGST.fulfilled,
      (state, action: PayloadAction<IGST[]>) => {
        state.loading = false;
        state.GSTList = action.payload || [];
      }
    );
    builder.addCase(getAllGST.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.GSTList = [];
    });
    builder.addCase(createGST.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createGST.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createGST.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateGST.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateGST.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateGST.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteGST.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteGST.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteGST.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getGSTByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getGSTByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.GSTDetail = action.payload;
    });
    builder.addCase(getGSTByID.rejected, (state, action) => {
      state.formLoading = false;
      state.GSTDetail = initialState.GSTDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveGST.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveGST.fulfilled,
      (state, action: PayloadAction<IGST[]>) => {
        state.loading = false;
        state.GSTList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveGST.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.GSTList = [];
    });
    builder.addCase(ActiveInactiveGST.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveGST.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveGST.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearGSTDetails, clearGSTID, setGSTID } = gstSlice.actions;
export default gstSlice.reducer;
