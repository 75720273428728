import React from "react";
import { Typography } from "@progress/kendo-react-common";
// import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
// import { GiMoneyStack, GiTicket } from "react-icons/gi";
// import {
//   TbCalendarTime,
//   TbFileTime,
//   TbMapRoute,
//   TbPlaneInflight,
// } from "react-icons/tb";
// import { MdEditDocument } from "react-icons/md";
// import { useNavigate } from "react-router-dom";
// import DashBoardCard from "./DashBoardCard";

const Dashboard: React.FC = () => {
  // const navigate = useNavigate();

  return (
    <>
      <Typography.h4 style={{ margin: "50px", textAlign: "center" }}>
        Welcome to the AMT International
      </Typography.h4>
      {/* <GridLayout
        style={{ marginRight: 60, marginBottom: 50 }}
        gap={{ rows: 20, cols: 20 }}
        cols={[
          { width: "25%" },
          { width: "25%" },
          { width: "25%" },
          { width: "25%" },
        ]}
      >
        <GridLayoutItem style={{ width: "100%" }}>
          <DashBoardCard
            count={"100+"}
            title="Bookings"
            onClick={() => navigate("/tourbooking")}
            icon={<TbCalendarTime style={{ fontSize: 60, strokeWidth: 1 }} />}
            background="linear-gradient(to right, #FFBA96, #FE8F96, #FE8DAB)"
          />
        </GridLayoutItem>
        <GridLayoutItem style={{ width: "100%" }}>
          <DashBoardCard
            count={"200+"}
            title="Payments"
            icon={<GiMoneyStack style={{ fontSize: 60 }} />}
            background="linear-gradient(to right, #8DC8F8, #3E9EEA, #3899E6)"
          />
        </GridLayoutItem>
        <GridLayoutItem style={{ width: "100%" }}>
          <DashBoardCard
            count={"300+"}
            title="Route Voucher"
            onClick={() => navigate("/routevoucher")}
            icon={<TbMapRoute style={{ fontSize: 60, strokeWidth: 1 }} />}
            background="linear-gradient(to right, #82D9D1, #3ED2BE, #34D6BD)"
          />
        </GridLayoutItem>
        <GridLayoutItem style={{ width: "100%" }}>
          <DashBoardCard
            count={"400+"}
            title="Hotel Voucher"
            onClick={() => navigate("/hotelvoucher")}
            icon={<GiTicket style={{ fontSize: 60, strokeWidth: 1 }} />}
            background="linear-gradient(to left, rgb(217, 162, 60), rgb(255 202 81), rgb(254 210 102))"
          />
        </GridLayoutItem>
        <GridLayoutItem style={{ width: "100%" }}>
          <DashBoardCard
            count={"500+"}
            title="Pending Payments"
            icon={<TbPlaneInflight style={{ fontSize: 60, strokeWidth: 1 }} />}
            background="linear-gradient(to right, rgb(255 166 238), rgb(255 132 238), rgb(255 102 231))"
          />
        </GridLayoutItem>
        <GridLayoutItem style={{ width: "100%" }}>
          <DashBoardCard
            count={"600+"}
            title="Pending Forms"
            icon={<TbFileTime style={{ fontSize: 60, strokeWidth: 1 }} />}
            background="linear-gradient(to right, rgb(247 202 149), rgb(255 153 132), rgb(255 131 102))"
          />
        </GridLayoutItem>
        <GridLayoutItem style={{ width: "100%" }}>
          <DashBoardCard
            count={"700+"}
            title="Total Pax Dept"
            icon={<MdEditDocument style={{ fontSize: 60 }} />}
            background="linear-gradient(to right, rgb(108 227 140), rgb(129 234 110), rgb(117 216 72))"
          />
        </GridLayoutItem>
        <GridLayoutItem style={{ width: "100%" }}>
          <DashBoardCard
            count={"800+"}
            title="Pending Hotel Voucher"
            icon={<GiTicket style={{ fontSize: 60, strokeWidth: 1 }} />}
            background="linear-gradient(to right, rgb(125 207 227), rgb(113 223 229), rgb(13 178 204))"
          />
        </GridLayoutItem>
      </GridLayout> */}
    </>
  );
};

export default Dashboard;
