import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActiveInactiveSector,
  createSector,
  deleteSector,
  getAllActiveSector,
  getAllSector,
  getSectorByID,
  updateSector,
} from "./services/sector.services";
import { ISector, ISectorState } from "./sectorModel";

const initialState: ISectorState = {
  loading: false,
  error: "",
  SectorID: null,
  DocumentDetailsDeleteIndex: -1,
  SectorList: [],
  SectorDetail: {
    ID: null,
    RegionID: null,
    CityID: null,
    SectorType: "",
    SectorName: "",
    Prefix: "",
    VisaAmount: null,
    InsuranceAmount: null,
    Color: "",
    DocumentDetails: null,
    IsActive: 1,
  },
};

const sectorSlice = createSlice({
  name: "sector",
  initialState,
  reducers: {
    clearSectorDetails: (state) => {
      state.SectorDetail = initialState.SectorDetail;
    },
    setSectorID: (state, action) => {
      state.SectorID = action.payload;
    },
    clearSectorID: (state) => {
      state.SectorID = initialState.SectorID;
    },
    setDocumentDetailsDeleteIndex: (state, action) => {
      state.DocumentDetailsDeleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSector.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSector.fulfilled,
      (state, action: PayloadAction<ISector[]>) => {
        state.loading = false;
        state.SectorList = action.payload || [];
      }
    );
    builder.addCase(getAllSector.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SectorList = [];
    });
    builder.addCase(createSector.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createSector.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createSector.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateSector.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateSector.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateSector.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteSector.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteSector.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteSector.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getSectorByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getSectorByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.SectorDetail = action.payload;
    });
    builder.addCase(getSectorByID.rejected, (state, action) => {
      state.loading = false;
      state.SectorDetail = initialState.SectorDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveSector.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveSector.fulfilled,
      (state, action: PayloadAction<ISector[]>) => {
        state.loading = false;
        state.SectorList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveSector.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SectorList = [];
    });
    builder.addCase(ActiveInactiveSector.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveSector.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveSector.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearSectorDetails,
  clearSectorID,
  setSectorID,
  setDocumentDetailsDeleteIndex,
} = sectorSlice.actions;
export default sectorSlice.reducer;
