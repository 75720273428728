import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ShadowCard from "../../components/common/ShadowCard";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import FormTextField from "../../components/formFields/FormTextField";
import {
  createVendors,
  getVendorsByID,
  updateVendors,
} from "./services/vendors.services";
import { clearVendorsDetails } from "./vendorsSlice";
import { getAllActiveCities } from "../city/services/city.services";
import { getAllActiveStates } from "../state/services/state.services";
import { getAllActiveCountries } from "../country/services/country.services";
import { ICity } from "../city/cityModel";
import { findIndex } from "lodash";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { IState } from "../state/stateModel";
import { ICountry } from "../country/countryModel";
import { STATUSARRAY } from "../../_contstants/common";
import { getAllActiveVendorTypes } from "../vendortype/services/vendorType.services";
import { IVendorType } from "../vendortype/vendorTypeModel";
import VendorDetailsArray from "./VendorDetailsArray";

interface CityChangeWatcherProps {
  formRenderProps: FormRenderProps;
  CityList: ICity[];
}

const CityChangeWatcher: React.FC<CityChangeWatcherProps> = ({
  CityList,
  formRenderProps,
}) => {
  const cityID = formRenderProps.valueGetter("CityID");

  useEffect(() => {
    const CityID = cityID ? cityID : undefined;
    const cityIndex = findIndex(CityList, { ID: CityID });
    if (cityIndex > -1) {
      const city = CityList[cityIndex];
      formRenderProps.onChange("StateID", { value: city.StateID });
      formRenderProps.onChange("CountryID", { value: city.CountryID });
    } else {
      formRenderProps.onChange("CityID", { value: "" });
      formRenderProps.onChange("StateID", { value: "" });
      formRenderProps.onChange("CountryID", { value: "" });
    }
  }, [cityID]);
  return null;
};

const CreateVendors: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const VendorsID = location.state?.VendorsID;

  const VendorsDetail = useAppSelector((state) => state.vendor.VendorsDetail);
  const loading = useAppSelector((state) => state.vendor.loading);
  const CityList = useAppSelector((state) => state.city.CityList);
  const StateList = useAppSelector((state) => state.state.StateList);
  const CountryList = useAppSelector((state) => state.country.CountryList);
  const VendorTypeList = useAppSelector(
    (state) => state.vendorType.VendorTypeList
  );

  useEffect(() => {
    if (VendorsID) {
      dispatch(getVendorsByID(VendorsID));
    }
  }, [VendorsID]);

  useEffect(() => {
    dispatch(getAllActiveCities());
    dispatch(getAllActiveStates());
    dispatch(getAllActiveCountries());
    dispatch(getAllActiveVendorTypes());

    return () => {
      dispatch(clearVendorsDetails());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    const formData = {
      VendorTypeID: values?.VendorTypeID ?? "",
      VendorName: values?.VendorName ?? "",
      Address: values?.Address ?? "",
      CityID: values?.CityID ? +values?.CityID : null,
      StateID: values?.StateID ? +values?.StateID : null,
      CountryID: values?.CountryID ? +values?.CountryID : null,
      PinCode: values?.PinCode ? +values?.PinCode : null,
      PANNo: values?.PANNo ? values?.PANNo.toString() : "",
      GSTNo: values?.GSTNo ? values?.GSTNo.toString() : "",
      VendorDetails: values?.VendorDetails ?? null,
      IsActive: values?.IsActive === 1,
    };
    try {
      if (VendorsID) {
        const editPayload = {
          ...formData,
          ID: VendorsID,
        };
        const response = await dispatch(updateVendors(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
        }
      } else {
        const response = await dispatch(createVendors(formData));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/vendors");
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <div>
        <Form
          onSubmit={handleSubmit}
          initialValues={VendorsDetail}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <ShadowCard style={{ padding: 12 }}>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ rows: 5, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4>
                      {VendorsID ? "Update Vendor" : "Create Vendor"}
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="VendorName"
                      label="Vendor"
                      placeholder="Vendor Name"
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      id={"VendorTypeID"}
                      name={"VendorTypeID"}
                      label={"Vendor Type"}
                      placeholder={"Vendor Type"}
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={VendorTypeList?.map(
                        (venderType: IVendorType) => {
                          return {
                            value: venderType?.ID,
                            label: venderType?.VendorType,
                          };
                        }
                      )}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="Address"
                      label="Address"
                      placeholder="Address"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      id={"CityID"}
                      name={"CityID"}
                      label={"City"}
                      placeholder={"City"}
                      component={FormSelectionField}
                      options={CityList?.map((city: ICity) => {
                        return {
                          value: city?.ID,
                          label: city?.CityName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      id={"StateID"}
                      name={"StateID"}
                      label={"State"}
                      placeholder={"State"}
                      component={FormSelectionField}
                      // disabled={true}
                      options={StateList?.map((state: IState) => {
                        return {
                          value: state?.ID,
                          label: state?.StateName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      id={"CountryID"}
                      name={"CountryID"}
                      label={"Country"}
                      placeholder={"Country"}
                      component={FormSelectionField}
                      // disabled={true}
                      options={CountryList?.map((country: ICountry) => {
                        return {
                          value: country?.ID,
                          label: country?.CountryName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="PinCode"
                      label="Pincode"
                      type="number"
                      placeholder="Pincode"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="PANNo"
                      placeholder="PAN NO"
                      label="PAN"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="GSTNo"
                      placeholder="GST NO"
                      label="GST"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`IsActive`}
                      label="Status"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={STATUSARRAY?.map((status: any) => {
                        return {
                          value: status?.value,
                          label: status?.label,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <CityChangeWatcher
                    CityList={CityList}
                    formRenderProps={formRenderProps}
                  />
                </GridLayout>
              </ShadowCard>
              <ShadowCard style={{ padding: 12, marginTop: 10 }}>
                <GridLayout
                  // gap={{ rows: 0, cols: 10 }}
                  cols={[{ width: "100%" }]}
                >
                  <GridLayoutItem>
                    <Typography.h4>Vendor Details</Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{ borderBottom: "1px solid lightgray" }}
                  >
                    <FieldArray
                      formRenderProps={formRenderProps}
                      component={VendorDetailsArray}
                      name="VendorDetails"
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                    }}
                  >
                    <div>
                      <Button
                        type="button"
                        fillMode={"outline"}
                        themeColor={"primary"}
                        style={{ marginRight: 4 }}
                        onClick={() => navigate("/vendors")}
                      >
                        Cancel
                      </Button>
                      <ButtonWithLoading
                        label={VendorsID ? "Update" : "Create"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </ShadowCard>
            </FormElement>
          )}
        />
      </div>
    </>
  );
};

export default CreateVendors;
