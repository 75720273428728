import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IVehicleSharingPax } from "../vehicleSharingPaxModel";

export const getAllVehicleSharingPaxs = createAsyncThunk(
  "VehicleSharingPax/FindAllVehicleSharingPax",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VehicleSharingPax/FindAllVehicleSharingPax`,
        {
          Title: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Vehicle Sharing Paxs:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createVehicleSharingPax = createAsyncThunk(
  "VehicleSharingPax/InsertVehicleSharingPax",
  async (VehicleSharingPaxData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VehicleSharingPax/InsertVehicleSharingPax`,
        VehicleSharingPaxData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating vehicle sharing pax:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateVehicleSharingPax = createAsyncThunk(
  "VehicleSharingPax/UpdateVehicleSharingPax",
  async (VehicleSharingPaxData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VehicleSharingPax/UpdateVehicleSharingPax`,
        VehicleSharingPaxData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating vehicle sharing pax:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteVehicleSharingPax = createAsyncThunk(
  "VehicleSharingPax/DeleteVehicleSharingPax",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VehicleSharingPax/DeleteVehicleSharingPax`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting vehicle sharing pax:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getVehicleSharingPaxByID = createAsyncThunk(
  "VehicleSharingPax/FindByIDVehicleSharingPax",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VehicleSharingPax/FindByIDVehicleSharingPax`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        Title: result.Title,
        IsActive: result.IsActive ? 1 : 2,
      } as IVehicleSharingPax;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching vehicle sharing pax:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveVehicleSharingPaxs = createAsyncThunk(
  "VehicleSharingPax/FindAllActiveVehicleSharingPax",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/VehicleSharingPax/FindAllActiveVehicleSharingPax`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Vehicle Sharing Paxs:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveVehicleSharingPax = createAsyncThunk(
  "VehicleSharingPax/ActiveInActiveVehicleSharingPax",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VehicleSharingPax/ActiveInActiveVehicleSharingPax`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive vehicle sharing pax:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
