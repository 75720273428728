import React, { useEffect, useState } from "react";
import "./newLogin.scss";
import IMGLOGIN from "../../assets/Images/img-login.png";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import {
  passwordValidator,
  // phoneValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";

import { Link, useNavigate } from "react-router-dom";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  // clearOTP,
  // generateOTP,
  // setResend,
  employeeLogin,
} from "./loginSlice";
// import { Button } from "@progress/kendo-react-buttons";
import FormPasswordField from "../../components/formFields/FormPasswordField";
const NewLogin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.login.loading);
  // const OTPData = useAppSelector((state) => state.login.otpData);
  // const resend = useAppSelector((state) => state.login.resend);

  const [timer, setTimer] = useState(30);
  const [isTimerActive, setIsTimerActive] = useState(false);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (isTimerActive) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    if (timer === 0) {
      setIsTimerActive(false);
      setTimer(30);
      if (interval) {
        clearInterval(interval);
      }
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isTimerActive, timer]);

  /* const handleSubmit = async (values: any) => {
    const mobile_no = values.mobile;
    if (!OTPData || resend) {
      dispatch(setResend(false));
      dispatch(generateOTP(mobile_no));
      setIsTimerActive(true);
      return;
    }
    const data = {
      mobile_no: values.mobile,
      otp: values.otp,
    };
    const response = await dispatch(employeeLogin(data));
    if (response?.meta?.requestStatus === "fulfilled") {
      dispatch(clearOTP());
      dispatch(setResend(false));
      navigate("/");
    }
  }; */

  const handleSubmit = async (values: any) => {
    const data = {
      UserName: values.UserName,
      Password: values.Password,
    };

    const response = await dispatch(employeeLogin(data));
    if (response?.meta?.requestStatus === "fulfilled") {
      navigate("/");
    }
  };
  return (
    <div className="outter-container">
      <div className="inner-container">
        <div className="content-item image-side">
          <h4 className="company-title">AMT INTERNATIONAL</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Earum ad
            dignissimos cum laborum accusantium. Amet asperiores iure vel
            cupiditate mollitia!
          </p>
          <div className="image-container">
            <img src={IMGLOGIN} alt="login-img" />
          </div>
        </div>
        <div className="content-item form-side">
          {/* <div className="circle-big">
            <div className="inner-circle-big"></div>
          </div> */}
          <div className="form-card">
            <h6 className="form-title">MEMBER LOGIN</h6>
            <Form
              onSubmit={handleSubmit}
              initialValues={{
                UserName: "",
                Password: "",
                RememberMe: false,
              }}
              // OTP VERSTION
              // initialValues={{
              //   mobile: "",
              //   otp: null,
              // }}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement className="loginform">
                  {/* OTP VERSTION */}
                  {/* <Field
                    name={"mobile"}
                    placeholder="Mobile"
                    type={"number"}
                    component={FormTextField}
                    validator={phoneValidator}
                  />
                  {OTPData && (
                    <Field
                      name={"otp"}
                      type={"number"}
                      placeholder="OTP"
                      component={FormTextField}
                    />
                  )}
                  {OTPData && (
                    <Button
                      className="resend-btn"
                      type="submit"
                      disabled={loading || isTimerActive}
                      onClick={() => dispatch(setResend(true))}
                    >
                      {isTimerActive ? `Resend OTP in ${timer}s` : "Resend OTP"}
                    </Button>
                  )} */}

                  <Field
                    name={"UserName"}
                    type={"text"}
                    component={FormTextField}
                    label={"User Name"}
                    validator={requiredValidator}
                  />
                  <Field
                    name={"Password"}
                    type={"password"}
                    component={FormPasswordField}
                    label={"Password"}
                    validator={passwordValidator}
                  />

                  <ButtonWithLoading
                    // label={OTPData ? "Login" : "Generate OTP"}
                    label="Login"
                    themeColor="primary"
                    type="submit"
                    width="100%"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                    className="login-btn"
                  />
                  <hr />
                  <div className="register">
                    <p>
                      Not a member? <Link to="/signup">Create Account</Link>
                    </p>
                  </div>
                </FormElement>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLogin;
