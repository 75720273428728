import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActiveInactiveBank,
  BankDetailsImageUpload,
  createBank,
  deleteBank,
  getAllActiveBankes,
  getAllBankes,
  getBankByID,
  updateBank,
} from "./services/bank.services";
import { IBank, IBankInitialState } from "./bankModel";

const initialState: IBankInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  BankID: null,
  BankList: [],
  BankDetail: {
    ID: null,
    CompanyID: null,
    BankName: "",
    Branch: "",
    Address: "",
    AccountNo: "",
    IFSCCode: "",
    IsActive: 1,
  },
};

const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    clearBankDetails: (state) => {
      state.BankDetail = initialState.BankDetail;
    },
    setBankID: (state, action) => {
      state.BankID = action.payload;
    },
    clearBankID: (state) => {
      state.BankID = initialState.BankID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBankes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllBankes.fulfilled,
      (state, action: PayloadAction<IBank[]>) => {
        state.loading = false;
        state.BankList = action.payload || [];
      }
    );
    builder.addCase(getAllBankes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.BankList = [];
    });
    builder.addCase(createBank.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createBank.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createBank.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateBank.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateBank.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateBank.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteBank.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteBank.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteBank.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getBankByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getBankByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.BankDetail = action.payload;
    });
    builder.addCase(getBankByID.rejected, (state, action) => {
      state.loading = false;
      state.BankDetail = initialState.BankDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveBankes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveBankes.fulfilled,
      (state, action: PayloadAction<IBank[]>) => {
        state.loading = false;
        state.BankList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveBankes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.BankList = [];
    });
    builder.addCase(ActiveInactiveBank.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveBank.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveBank.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    // IMAGE UPLOAD
    builder.addCase(BankDetailsImageUpload.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(BankDetailsImageUpload.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(BankDetailsImageUpload.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearBankDetails, clearBankID, setBankID } = bankSlice.actions;
export default bankSlice.reducer;
