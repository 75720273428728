import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActiveInactiveAirline,
  createAirline,
  deleteAirline,
  getAirlineByID,
  getAllActiveAirlines,
  getAllAirlines,
  updateAirline,
} from "./services/airline.services";
import { IAirline, IAirlineState } from "./airlineModel";

const initialState: IAirlineState = {
  loading: false,
  error: "",
  AirlineID: null,
  AirlineList: [],
  AirlineDetail: {
    AirlineName: "",
    AirlinePrefix: "",
    AirlineCode: "",
    Remarks: "",
    TermsConditions: "",
    CancellationPolicy: "",
    Note: "",
    ImportantInformation: "",
    ContactDetails: "",
    IsActive: 1,
  },
};

const airlineSlice = createSlice({
  name: "airline",
  initialState,
  reducers: {
    clearAirlineDetails: (state) => {
      state.AirlineDetail = initialState.AirlineDetail;
    },
    setAirlineID: (state, action) => {
      state.AirlineID = action.payload;
    },
    clearAirlineID: (state) => {
      state.AirlineID = initialState.AirlineID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAirlines.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllAirlines.fulfilled,
      (state, action: PayloadAction<IAirline[]>) => {
        state.loading = false;
        state.AirlineList = action.payload || [];
      }
    );
    builder.addCase(getAllAirlines.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AirlineList = [];
    });
    builder.addCase(createAirline.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createAirline.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(createAirline.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateAirline.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateAirline.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAirline.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteAirline.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteAirline.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteAirline.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAirlineByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAirlineByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.AirlineDetail = action.payload;
    });
    builder.addCase(getAirlineByID.rejected, (state, action) => {
      state.loading = false;
      state.AirlineDetail = initialState.AirlineDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveAirlines.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveAirlines.fulfilled,
      (state, action: PayloadAction<IAirline[]>) => {
        state.loading = false;
        state.AirlineList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveAirlines.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AirlineList = [];
    });
    builder.addCase(ActiveInactiveAirline.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveAirline.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveAirline.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearAirlineDetails, clearAirlineID, setAirlineID } =
  airlineSlice.actions;
export default airlineSlice.reducer;
