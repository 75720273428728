import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IVendorType } from "../vendorTypeModel";

export const getAllVendorTypes = createAsyncThunk(
  "VendorType/FindAllVendorType",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VendorType/FindAllVendorType`,
        {
          VendorType: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Vendor Types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createVendorType = createAsyncThunk(
  "VendorType/InsertVendorType",
  async (VendorTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VendorType/InsertVendorType`,
        VendorTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating vendor type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateVendorType = createAsyncThunk(
  "VendorType/UpdateVendorType",
  async (VendorTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VendorType/UpdateVendorType`,
        VendorTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating vendor type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteVendorType = createAsyncThunk(
  "VendorType/DeleteVendorType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VendorType/DeleteVendorType`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting vendor type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getVendorTypeByID = createAsyncThunk(
  "VendorType/FindByIDVendorType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VendorType/FindByIDVendorType`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        VendorType: result.VendorType,
        IsActive: result.IsActive ? 1 : 2,
      } as IVendorType;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching vendor type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveVendorTypes = createAsyncThunk(
  "VendorType/FindAllActiveVendorType",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/VendorType/FindAllActiveVendorType`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching vendor types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveVendorType = createAsyncThunk(
  "VendorType/ActiveInActiveVendorType",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VendorType/ActiveInActiveVendorType`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive vendor type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
