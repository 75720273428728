import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IPaxType } from "../paxTypeModel";

export const getAllPaxTypes = createAsyncThunk(
  "PaxType/FindAllPaxType",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PaxType/FindAllPaxType`,
        {
          PaxType: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Pax Types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createPaxType = createAsyncThunk(
  "PaxType/InsertPaxType",
  async (PaxTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PaxType/InsertPaxType`,
        PaxTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating pax type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updatePaxType = createAsyncThunk(
  "PaxType/UpdatePaxType",
  async (PaxTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PaxType/UpdatePaxType`,
        PaxTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating pax type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deletePaxType = createAsyncThunk(
  "PaxType/DeletePaxType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PaxType/DeletePaxType`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting pax type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPaxTypeByID = createAsyncThunk(
  "PaxType/FindByIDPaxType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PaxType/FindByIDPaxType`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        PaxType: result.PaxType,
        PaxCount: result.PaxCount,
        Adult: result.Adult,
        ExtraAdult: result.ExtraAdult,
        CWB: result.CWB,
        CNB: result.CNB,
        Infant: result.Infant,
        IsActive: result.IsActive ? 1 : 2,
      } as IPaxType;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching pax type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActivePaxTypes = createAsyncThunk(
  "PaxType/FindAllActivePaxType",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/PaxType/FindAllActivePaxType`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching pax types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactivePaxType = createAsyncThunk(
  "PaxType/ActiveInActivePaxType",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PaxType/ActiveInActivePaxType`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive pax type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
