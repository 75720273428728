import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IRegion } from "../regionModel";

export const getAllRegions = createAsyncThunk(
  "Region/FindAllRegion",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Region/FindAllRegion`,
        {
          RegionName: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Regions:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createRegion = createAsyncThunk(
  "Region/InsertRegion",
  async (RegionData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Region/InsertRegion`,
        RegionData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating region:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateRegion = createAsyncThunk(
  "Region/UpdateRegion",
  async (RegionData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Region/UpdateRegion`,
        RegionData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating region:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteRegion = createAsyncThunk(
  "Region/DeleteRegion",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Region/DeleteRegion`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting region:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getRegionByID = createAsyncThunk(
  "Region/FindByIDRegion",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Region/FindByIDRegion`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        RegionName: result.RegionName,
        IsActive: result.IsActive ? 1 : 2,
      } as IRegion;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching region:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveRegions = createAsyncThunk(
  "Region/FindAllActiveRegion",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Region/FindAllActiveRegion`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Regions:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveRegion = createAsyncThunk(
  "Region/ActiveInActiveRegion",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Region/ActiveInActiveRegion`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive region:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
