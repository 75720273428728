import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IDocument } from "../documentModel";

export const getAllDocument = createAsyncThunk(
  "Document/FindAllDocument",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Document/FindAllDocument`,
        {
          DocumentName: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Document:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createDocument = createAsyncThunk(
  "Document/InsertDocument",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Document/InsertDocument`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Document:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateDocument = createAsyncThunk(
  "Document/UpdateDocument",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Document/UpdateDocument`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating document:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteDocument = createAsyncThunk(
  "Document/DeleteDocument",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Document/DeleteDocument`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting document:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getDocumentByID = createAsyncThunk(
  "Document/FindByIDDocument",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Document/FindByIDDocument`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        DocumentName: result.DocumentName,
        Remarks: result.Remarks,
        IsActive: result.IsActive === true ? 1 : 2,
      } as IDocument;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Document:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveDocument = createAsyncThunk(
  "Document/FindAllActiveDocument",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Document/FindAllActiveDocument`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Document:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveDocument = createAsyncThunk(
  "Document/ActiveInActiveDocument",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Document/ActiveInActiveDocument`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Document:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
