import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch } from "../../app/hooks";
import { Anchor, IDrawerState, opened } from "./muiDrawerSlice";
import { FiEdit } from "react-icons/fi";
import { Tooltip } from "@progress/kendo-react-tooltip";

interface EditButtonType {
  ID: number;
  name: string;
  anchor?: Anchor;
  label?: string;
  isIndirect?: boolean;
  drawerWidth?: number;
  tooltipTitle?: string;
}

const ButtonEdit: React.FC<EditButtonType> = (props) => {
  const dispatch = useAppDispatch();

  const handleEdit = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    const drawerOptions: IDrawerState = {
      open: true,
      anchor: props?.anchor || "right",
      name: props.name,
      drawerWidth: props?.drawerWidth || 400,
      data: props.ID,
      label: props.label,
      isIndirect: props?.isIndirect || false,
    };
    dispatch(opened(drawerOptions));
  };
  return props?.label ? (
    <div className="fw-600" onClick={handleEdit}>
      {props.label}
    </div>
  ) : (
    <Tooltip anchorElement="target" position="top" parentTitle={true}>
      <Button
        type="button"
        fillMode="flat"
        themeColor="primary"
        size="small"
        title={props?.tooltipTitle}
        onClick={handleEdit}
        style={{ width: 35, height: 35, position: "relative" }}
      >
        <FiEdit style={{ fontSize: "20px" }} className="absolute-position" />
      </Button>
    </Tooltip>
  );
};

export default ButtonEdit;
