import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IVehicleType } from "../vehicleTypeModel";

export const getAllVehicleTypes = createAsyncThunk(
  "VehicleType/FindAllVehicleType",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VehicleType/FindAllVehicleType`,
        {
          VehicleType: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Vehicle Types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createVehicleType = createAsyncThunk(
  "VehicleType/InsertVehicleType",
  async (VehicleTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VehicleType/InsertVehicleType`,
        VehicleTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating vehicle type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateVehicleType = createAsyncThunk(
  "VehicleType/UpdateVehicleType",
  async (VehicleTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VehicleType/UpdateVehicleType`,
        VehicleTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating vehicle type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteVehicleType = createAsyncThunk(
  "VehicleType/DeleteVehicleType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VehicleType/DeleteVehicleType`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting vehicle type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getVehicleTypeByID = createAsyncThunk(
  "VehicleType/FindByIDVehicleType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VehicleType/FindByIDVehicleType`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        VehicleType: result.VehicleType,
        Capacity: result.Capacity,
        IsActive: result.IsActive === true ? 1 : 2,
      } as IVehicleType;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching vehicle type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveVehicleTypes = createAsyncThunk(
  "VehicleType/FindAllActiveVehicleType",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/VehicleType/FindAllActiveVehicleType`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching vehicle types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveVehicleType = createAsyncThunk(
  "VehicleType/ActiveInActiveVehicleType",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VehicleType/ActiveInActiveVehicleType`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive vehicle type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
