import React, { useCallback, useEffect } from "react";
import {
  GridLayout,
  GridLayoutItem,
  Stepper,
  StepperChangeEvent,
} from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import TourBookingDetails from "./tourbookingforms/TourBookingDetails";
import TouristInfo from "./tourbookingforms/TouristInfo";
import {
  clearTourBookingActiveStep,
  setTourBookingActiveStep,
} from "./tourBookingSlice";
import { useLocation } from "react-router-dom";
import TourPlaces from "./tourbookingforms/TourPlaces";
import TourItinerary from "./tourbookingforms/TourItinerary";

type ActiveStepProps = {
  step: number;
};

const steps = [
  { label: "Tour Booking", isValid: undefined },
  { label: "Tourist Info", isValid: undefined },
  { label: "Tour Places", isValid: undefined },
  { label: "Tourist Itinerary", isValid: undefined },
];

const CreateTourBooking: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  // const TourBookingID = location.state?.TourBookingID;
  const ActiveStep = useAppSelector((state) => state.tourBooking.ActiveStep);

  useEffect(() => {
    return () => {
      dispatch(clearTourBookingActiveStep());
    };
  }, []);

  // useEffect(() => {
  //   if (TourBookingID) {
  //     dispatch(getTourBookingByID(TourBookingID));
  //   }
  // }, [TourBookingID]);

  const renderActiveStep: React.FC<ActiveStepProps> = useCallback(
    ({ step }) => {
      switch (step) {
        case 0:
          return <TourBookingDetails />;
        case 1:
          return <TouristInfo />;
        case 2:
          return <TourPlaces />;
        case 3:
          return <TourItinerary />;
        default:
          return <TourBookingDetails />;
      }
    },
    [ActiveStep]
  );

  const handleChange = (e: StepperChangeEvent) => {
    // if (TourBookingID) {
    //   dispatch(getTourBookingByID(TourBookingID));
    // }

    if (location.pathname !== "/tourbooking/create" || e?.value === 0) {
      dispatch(setTourBookingActiveStep(e.value));
    }
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      <GridLayoutItem
        style={{
          padding: "15px 0",
        }}
      >
        <Stepper value={ActiveStep} onChange={handleChange} items={steps} />
      </GridLayoutItem>
      <GridLayoutItem>{renderActiveStep({ step: ActiveStep })}</GridLayoutItem>
    </GridLayout>
  );
};

export default CreateTourBooking;
