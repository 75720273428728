import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormTextField from "../../../components/formFields/FormTextField";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import {
  deleteTourBookingItinerary,
  getTourBookingItinerariesByTourBookingID,
  insertTourBookingItinerary,
  updateTourBookingItinerary,
} from "../services/tourBooking.services";
import RippleButton from "../../../components/common/RippleButton";
import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { Button } from "@progress/kendo-react-buttons";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import IconButton from "../../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import ShadowCard from "../../../components/common/ShadowCard";
import {
  clearTourBookingItineraryDetails,
  clearTourBookingItineraryID,
  clearTourBookingItineraryList,
  setTourBookingItineraryDetails,
  setTourBookingItineraryID,
} from "../tourBookingSlice";
import { IItinerary } from "../../itinerary/itineraryModel";
import { getItineraryBySectorID } from "../../itinerary/services/itinerary.services";
import { decreaseTourBookingActiveStep } from "../tourBookingSlice";

// interface FormChangeWatcherProps {
//   formRenderProps: FormRenderProps;
// }
// const DayChangeWatcher: React.FC<FormChangeWatcherProps> = ({
//   formRenderProps,
// }) => {
//   const TourBookingItineraryDetails = useAppSelector(
//     (state) => state.tourBooking.TourBookingItineraryDetails
//   );
//   const TourBookingItineraryList = useAppSelector(
//     (state) => state.tourBooking.TourBookingItineraryList
//   );

//   useEffect(() => {
//     if (
//       !TourBookingItineraryDetails?.ID &&
//       TourBookingItineraryList &&
//       TourBookingItineraryList?.length > 0
//     ) {
//       formRenderProps.onChange("DayNo", {
//         value: TourBookingItineraryList?.length + 1,
//       });
//     } else {
//       formRenderProps.onChange("DayNo", {
//         value: 1,
//       });
//     }
//   }, [TourBookingItineraryList, TourBookingItineraryDetails]);
//   return null;
// };

const TourItineraryChangeWatcher: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const SectorID = location.state?.SectorID;

  useEffect(() => {
    if (SectorID) {
      dispatch(getItineraryBySectorID(+SectorID));
    }
  }, [SectorID]);
  return null;
};

const TourItinerary = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TourBookingID = location.state?.TourBookingID;

  useEffect(() => {
    dispatch(getTourBookingItinerariesByTourBookingID(+TourBookingID));
    return () => {
      dispatch(clearTourBookingItineraryDetails());
      dispatch(clearTourBookingItineraryList());
    };
  }, []);

  return (
    <>
      <DeleteTourBookingItineraryDialog />
      <TourBookingItineraryForm />
      <TourBookingItineraryGridComponent />
    </>
  );
};

const TourBookingItineraryForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const TourBookingID = location.state?.TourBookingID;
  const loading = useAppSelector((state) => state.tourBooking.loading);
  const ItineraryList = useAppSelector(
    (state) => state.itinerary.ItineraryList
  );
  const TourBookingItineraryDetails = useAppSelector(
    (state) => state.tourBooking.TourBookingItineraryDetails
  );
  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [TourBookingItineraryDetails]);

  const handleSubmit = async (values: any) => {
    try {
      if (!TourBookingItineraryDetails?.ID) {
        const insertPayload = {
          TourBookingID: +TourBookingID,
          // TourID: +TourID,
          DayNo: values?.DayNo ? +values?.DayNo : null,
          ItineraryTime: values?.ItineraryTime ? values?.ItineraryTime : "",
          ItineraryID: values?.ItineraryID ? +values?.ItineraryID : null,
          Position: values?.Position ? +values?.Position : null,
        };
        const response = await dispatch(
          insertTourBookingItinerary(insertPayload)
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(getTourBookingItinerariesByTourBookingID(+TourBookingID));
          setFormKey(formKey + 1);
          dispatch(clearTourBookingItineraryDetails());
        }
      } else {
        const editPayload = {
          TourBookingID: +TourBookingID,
          ID: values?.ID ? +values?.ID : null,
          // TourID: +TourID,
          DayNo: values?.DayNo ? +values?.DayNo : null,
          ItineraryTime: values?.ItineraryTime ? values?.ItineraryTime : "",
          ItineraryID: values?.ItineraryID ? +values?.ItineraryID : null,
          Position: values?.Position ? +values?.Position : null,
        };
        const response = await dispatch(
          updateTourBookingItinerary(editPayload)
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(getTourBookingItinerariesByTourBookingID(+TourBookingID));
          setFormKey(formKey + 1);
          dispatch(clearTourBookingItineraryDetails());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={TourBookingItineraryDetails}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 10 }}>
            <GridLayout
              style={{ marginRight: 30 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "10%" },
                { width: "20%" },
                { width: "60%" },
                { width: "10%" },
              ]}
            >
              <GridLayoutItem colSpan={5}>
                <Typography.h4>{"Tour Itinerary"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`DayNo`}
                  label="Day No"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`ItineraryTime`}
                  label="Itinerary Time"
                  component={FormTextField}
                  // validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`ItineraryID`}
                  label={"Itinerary Title"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={ItineraryList?.map((itinerary: IItinerary) => {
                    return {
                      value: itinerary?.ID,
                      label: itinerary?.ItineraryTitle,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="Position"
                  label="Position"
                  type="number"
                  validator={requiredValidator}
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={4}
                style={{ textAlign: "end", marginTop: 20 }}
              >
                <RippleButton
                  type="button"
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => dispatch(decreaseTourBookingActiveStep())}
                >
                  Previous
                </RippleButton>
                <RippleButton
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setFormKey(formKey + 1);
                    dispatch(clearTourBookingItineraryDetails());
                  }}
                >
                  Clear
                </RippleButton>
                <ButtonWithLoading
                  label={TourBookingItineraryDetails?.ID ? "Update" : "Add"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
                <RippleButton
                  fillMode="solid"
                  themeColor="primary"
                  style={{ marginLeft: 10 }}
                  onClick={() => navigate("/tourbooking")}
                >
                  Go to Tour Booking List
                </RippleButton>
              </GridLayoutItem>
            </GridLayout>
            {/* <DayChangeWatcher formRenderProps={formRenderProps} /> */}
            <TourItineraryChangeWatcher />
          </ShadowCard>
        </FormElement>
      )}
    />
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const TourBookingItineraryGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const TourBookingItineraryList = useAppSelector(
    (state) => state.tourBooking.TourBookingItineraryList
  );
  const loading = useAppSelector((state) => state.tour.loading);

  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? TourBookingItineraryList.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (item: any) => {
      const payload = {
        ID: item.ID,
        TourBookingID: item.TourBookingID,
        TourID: item.TourID,
        DayNo: item.DayNo,
        ItineraryTime: item.ItineraryTime ? item.ItineraryTime : "",
        ItineraryID: item.ItineraryID,
        Position: item.Position,
      };
      dispatch(setTourBookingItineraryDetails(payload));
    };
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteTourBookingItinerary"));
      dispatch(setTourBookingItineraryID(ID));
    };

    return (
      <td
        className={props.className}
        style={{
          ...props.style,
          textAlign: "center",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEdit(props.dataItem)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem?.ID)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 14 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {TourBookingItineraryList && TourBookingItineraryList.length ? (
        <KendoGrid
          style={{ marginTop: 20 }}
          data={TourBookingItineraryList}
          skip={page.skip}
          take={page.take}
          total={TourBookingItineraryList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="DayNo"
            title="Day No."
            width={90}
            cell={(props) => <td>Day {props.dataItem.DayNo}</td>}
          />
          <Column field="ItineraryTime" title="Time" width={150} />
          <Column field="ItineraryTitle" title="Itinerary Title" />
          <Column field="Position" title="Position" width={90} />
          <Column
            field="ID"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
            locked={true}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteTourBookingItineraryDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TourBookingID = location.state?.TourBookingID;
  const TourBookingItineraryID = useAppSelector(
    (state) => state.tourBooking.TourBookingItineraryID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteTourBookingItinerary(ID));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(getTourBookingItinerariesByTourBookingID(TourBookingID));
        dispatch(closeDialog());
        dispatch(clearTourBookingItineraryID());
      } else {
        dispatch(getTourBookingItinerariesByTourBookingID(TourBookingID));
        dispatch(closeDialog());
        dispatch(clearTourBookingItineraryID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteTourBookingItinerary" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Tour Booking Itinerary"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Tour Booking Itinerary?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(TourBookingItineraryID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default TourItinerary;
