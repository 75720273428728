import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  createSpecialityType,
  getAllSpecialityTypes,
  getSpecialityTypeByID,
  updateSpecialityType,
} from "./services/specialityType.services";
import { clearSpecialityTypeDetails } from "./specialityTypeSlice";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { STATUSARRAY } from "../../_contstants/common";

const CreateSpecialityType: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const SpecialityTypeDetail = useAppSelector(
    (state) => state.specialityType.SpecialityTypeDetail
  );
  const SpecialityTypeID = useAppSelector((state) => state.muidrawer.data);
  const formLoading = useAppSelector(
    (state) => state.specialityType.formLoading
  );

  useEffect(() => {
    if (SpecialityTypeID) {
      dispatch(getSpecialityTypeByID(SpecialityTypeID));
    }
  }, [SpecialityTypeID]);

  useEffect(() => {
    return () => {
      dispatch(clearSpecialityTypeDetails());
      dispatch(closed());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      if (!SpecialityTypeID) {
        const insertPayload = {
          SpecialityType: values.SpecialityType,
          Prefix: values.Prefix,
          IsActive: values?.IsActive === 1,
        };
        const response = await dispatch(createSpecialityType(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearSpecialityTypeDetails());
          dispatch(getAllSpecialityTypes());
        }
      } else {
        const editPayload = {
          ID: SpecialityTypeID,
          SpecialityType: values.SpecialityType,
          Prefix: values.Prefix,
          IsActive: values?.IsActive === 1,
        };
        const response = await dispatch(updateSpecialityType(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearSpecialityTypeDetails());
          dispatch(getAllSpecialityTypes());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={SpecialityTypeDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem className="master-drawer-header">
              <Typography.h4 className="m-0">
                {SpecialityTypeID
                  ? "Update Speciality Type"
                  : "Create Speciality Type"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="SpecialityType"
                label="Speciality Type"
                placeholder="i.e. Family Tour"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="Prefix"
                label="Prefix"
                placeholder="i.e. FT"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"IsActive"}
                name={"IsActive"}
                label={"Status"}
                component={FormSelectionField}
                validator={requiredValidator}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status?.value,
                    label: status?.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "start",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={SpecialityTypeID ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateSpecialityType;
