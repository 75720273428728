import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import React, { useEffect } from "react";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import IconButton from "../../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import FormTextField from "../../../components/formFields/FormTextField";
import FormDatePicker from "../../../components/formFields/FormDateField";
import { getAllActiveMealTypes } from "../../mealtype/services/mealtype.services";
import { setTourBookingDeleteIndex } from "../tourBookingSlice";
import { getAllActiveHotels } from "../../hotel/services/hotel.services";
import { requiredValidator } from "../../../components/formFields/CommonValidator";

const PlaceInfoArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const paxDeleteIndex = useAppSelector(
    (state) => state.tourBooking.deleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const TourBookingDataListDetails = useAppSelector(
    (state) => state.tourBooking.TourBookingDataListDetails
  );
  // const TourBookingDetail = useAppSelector((state) => state.tourBooking.TourBookingDetail);
  const HotelList = useAppSelector((state) => state.hotel.HotelList);

  useEffect(() => {
    dispatch(getAllActiveMealTypes());
    dispatch(getAllActiveHotels());
  }, []);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("placeInfoDeleteDialog"));
    dispatch(setTourBookingDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setTourBookingDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        PlaceID: null,
        HotelID: null,
        CheckinDate: null,
        NoOfNights: null,
        CheckOutDate: null,
      },
    });
  }, [fieldArrayRenderProps]);

  const filterHotels = (CityID: number) => {
    if (CityID) {
      const result = HotelList.filter((hotel: any) => {
        return hotel.CityID === CityID;
      });
      return result.map((hotel) => {
        return {
          value: hotel.ID,
          label: hotel.HotelName,
        };
      });
    }
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {!fieldArrayRenderProps.value ? pushElementInarray() : null}
      <GridLayoutItem style={{ marginBottom: 10 }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Place
        </Button>
      </GridLayoutItem>
      {dialogName === "placeInfoDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Place Information"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(paxDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map(
          (TourPlaceBooking: any, index: number) => (
            <GridLayoutItem key={index}>
              <Card className="fieldarray-card">
                <GridLayout
                  style={{ marginRight: 50 }}
                  gap={{ rows: 0, cols: 10 }}
                  cols={[
                    { width: "19%" },
                    { width: "19%" },
                    { width: "19%" },
                    { width: "17%" },
                    { width: "19%" },
                    { width: "7%" },
                  ]}
                >
                  <GridLayoutItem>
                    <Field
                      name={`TourPlaceBooking.${index}.PlaceID`}
                      label="Place"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={TourBookingDataListDetails?.PlaceData?.map(
                        (place: any) => {
                          return {
                            value: place?.PlaceID,
                            label: place?.Place,
                          };
                        }
                      )}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`TourPlaceBooking.${index}.HotelID`}
                      label="Hotel"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={
                        HotelList && filterHotels(TourPlaceBooking.PlaceID)
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`TourPlaceBooking.${index}.CheckinDate`}
                      label="Check in Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`TourPlaceBooking.${index}.NoOfNights`}
                      label="No Of Nights"
                      type="number"
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`TourPlaceBooking.${index}.CheckOutDate`}
                      label="Check out Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      textAlign: "right",
                      marginTop: 36,
                    }}
                  >
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      // disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      size={"small"}
                      fillMode={"solid"}
                      type="button"
                      style={{ height: 24, width: 24 }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  </GridLayoutItem>
                </GridLayout>
              </Card>
            </GridLayoutItem>
          )
        )}
    </GridLayout>
  );
};

export default PlaceInfoArray;
