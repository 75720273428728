import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import React, { useEffect, useRef, useState } from "react";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import FormTextField from "../../../components/formFields/FormTextField";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { NAMEPREFIX } from "../../../_contstants/common";
import { IFoodOption } from "../../foodoption/foodOptionModel";
import FormDatePicker from "../../../components/formFields/FormDateField";
import { INationality } from "../../nationality/nationalityModel";
import moment from "moment";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormCheckbox from "../../../components/formFields/FormCheckbox";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa6";
import { RiAddBoxFill } from "react-icons/ri";
import { Tooltip } from "@progress/kendo-react-tooltip";
import {
  deleteTouristDocument,
  getByIDTourBookingStep2,
  uploadTourBookingTouriestDoc,
} from "../services/tourBooking.services";
import { useLocation } from "react-router-dom";
import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import {
  clearTourTouristDocDeleteIndex,
  setTourTouristDocDeleteIndex,
} from "../tourBookingSlice";

const TouristPaxRoomArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TourBookingID = location.state?.TourBookingID;
  const loading = useAppSelector((state) => state.tourBooking.loading);
  const FoodOptionList = useAppSelector(
    (state) => state.foodOption.FoodOptionList
  );
  const NationalityList = useAppSelector(
    (state) => state.nationality.NationalityList
  );

  const [documentID, setDocumentID] = useState<number | null>(null);
  const [paxID, setPaxID] = useState<number | null>(null);

  const name = fieldArrayRenderProps.name || "";

  const fileInputRef = useRef<any>(null);

  const handleButtonClick = (documentID: number, touristPaxID: number) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      setDocumentID(documentID);
      setPaxID(touristPaxID);
    }
  };

  const handleViewDoc = (url: any) => {
    if (url) {
      window.open(url);
    }
  };
  const handleFileChange = async (e: any) => {
    const file = e.target.files[0];
    if (file && documentID && paxID) {
      if (fileInputRef.current) {
        setDocumentID(null);
        setPaxID(null);
        fileInputRef.current.value = "";
      }
      const formData = new FormData();
      formData.append("DocumentID", `${documentID}`);
      formData.append("TouristPaxID", `${paxID}`);
      formData.append("FileName", file);

      const response = await dispatch(uploadTourBookingTouriestDoc(formData));

      if (response.meta.requestStatus === "fulfilled") {
        dispatch(getByIDTourBookingStep2(TourBookingID));
      }
    }
  };

  const handleDeleteDoc = (id: any) => {
    dispatch(openDialog("deleteTouristDocument"));
    dispatch(setTourTouristDocDeleteIndex(id));
  };

  useEffect(() => {
    if (fileInputRef.current) {
      // setDocumentID(null);
      // setPaxID(null);
      fileInputRef.current.value = "";
    }
  }, [fieldArrayRenderProps?.value?.map((e: any) => e?.DocumentList)]);

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        Initial: "",
        FirstName: "",
        LastName: "",
        MobileNo: "",
        DOB: "",
        PanNo: "",
        AadharNo: "",
        FoodOptionID: null,
        PassportNo: "",
        ExpiryDate: "",
        NationalityID: null,
        Amount: null,
        ExtraCost: null,
        DiscountAmount: null,
        IsVisa: false,
      },
    });
  }, [fieldArrayRenderProps]);

  return (
    <>
      <DeleteTourBookingDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        {!fieldArrayRenderProps.value ? pushElementInarray() : null}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (TouristPaxInfo: any, innerIndex: number) => (
              <GridLayoutItem key={innerIndex}>
                <Card className="fieldarray-card">
                  <GridLayout
                    style={{ marginRight: 30 }}
                    gap={{ rows: 0, cols: 10 }}
                    cols={[
                      { width: "25%" },
                      { width: "25%" },
                      { width: "25%" },
                      { width: "25%" },
                    ]}
                  >
                    <GridLayoutItem>
                      <Field
                        name={`${name}.${innerIndex}.Initial`}
                        label="Initial"
                        component={FormSelectionField}
                        options={NAMEPREFIX.map((item: any) => {
                          return {
                            value: item.value,
                            label: item.label,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`${name}.${innerIndex}.FirstName`}
                        label="First Name"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`${name}.${innerIndex}.LastName`}
                        label="Last Name"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`${name}.${innerIndex}.MobileNo`}
                        label="Mobile No"
                        type="number"
                        max="10"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`${name}.${innerIndex}.DOB`}
                        label="Date of Birth"
                        format="dd/MM/yyyy"
                        component={FormDatePicker}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`${name}.${innerIndex}.PanNo`}
                        label="Pancard No"
                        component={FormTextField}
                        // validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`${name}.${innerIndex}.AadharNo`}
                        label="Aadhar No"
                        component={FormTextField}
                        validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`${name}.${innerIndex}.FoodOptionID`}
                        label="Food Preferences"
                        component={FormSelectionField}
                        options={FoodOptionList?.map((food: IFoodOption) => {
                          return {
                            value: food?.ID,
                            label: food?.Name,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`${name}.${innerIndex}.PassportNo`}
                        label="Passport No"
                        component={FormTextField}
                        validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`${name}.${innerIndex}.ExpiryDate`}
                        label="Expiry Date"
                        format="dd/MM/yyyy"
                        minDate={moment().toDate()}
                        component={FormDatePicker}
                        validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`${name}.${innerIndex}.NationalityID`}
                        label="Nationality"
                        component={FormSelectionField}
                        options={NationalityList?.map(
                          (nationality: INationality) => {
                            return {
                              value: nationality?.ID,
                              label: nationality?.Nationality,
                            };
                          }
                        )}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem colSpan={1}></GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`${name}.${innerIndex}.Amount`}
                        label="Amount"
                        type="number"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`${name}.${innerIndex}.ExtraCost`}
                        label="Extra Cost"
                        type="number"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`${name}.${innerIndex}.DiscountAmount`}
                        label="Discount Amount"
                        type="number"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem
                      style={{ width: "100%", marginTop: 24, paddingLeft: 20 }}
                    >
                      <Field
                        name={`${name}.${innerIndex}.IsVisa`}
                        label="Visa Required"
                        component={FormCheckbox}
                      />
                    </GridLayoutItem>
                  </GridLayout>
                  {fieldArrayRenderProps?.value[innerIndex]?.ID &&
                    fieldArrayRenderProps?.value[innerIndex]?.DocumentList &&
                    fieldArrayRenderProps?.value[innerIndex]?.DocumentList
                      ?.length > 0 && (
                      <GridLayout
                        style={{ marginRight: 10, marginTop: 16 }}
                        gap={{ rows: 10, cols: 10 }}
                        cols={[{ width: "33.33%" }, { width: "66.66%" }]}
                      >
                        <GridLayoutItem colSpan={2}>
                          <Typography.h4
                            style={{
                              marginBottom: 10,
                              textDecoration: "underline",
                            }}
                          >
                            Document List
                          </Typography.h4>
                        </GridLayoutItem>
                        <GridLayoutItem colSpan={2}>
                          <table>
                            {fieldArrayRenderProps?.value[
                              innerIndex
                            ]?.DocumentList?.map(
                              (e: any) =>
                                e?.DocumentID && (
                                  <tr>
                                    <td style={{ paddingBottom: 10 }}>
                                      <Typography.h5
                                        style={{ marginBottom: 0 }}
                                      >
                                        {e?.DocumentName} &nbsp;
                                      </Typography.h5>
                                    </td>
                                    <td style={{ paddingBottom: 10 }}>
                                      <b>{" : "}</b>
                                    </td>
                                    <input
                                      type="file"
                                      id="docUpload"
                                      ref={fileInputRef}
                                      onChange={(event) =>
                                        handleFileChange(event)
                                      }
                                      style={{ display: "none" }}
                                    />
                                    {e?.FileName ? (
                                      <td
                                        style={{
                                          padding: "0 0 10px 10px",
                                          display: "flex",
                                          gap: 10,
                                        }}
                                      >
                                        <Tooltip
                                          anchorElement="target"
                                          position="top"
                                          parentTitle={true}
                                        >
                                          <Button
                                            type="button"
                                            fillMode="flat"
                                            themeColor="primary"
                                            size="small"
                                            title={"View"}
                                            onClick={() =>
                                              handleViewDoc(e?.FileName)
                                            }
                                            style={{
                                              width: 35,
                                              height: 35,
                                              position: "relative",
                                            }}
                                          >
                                            <FaEye
                                              style={{
                                                fontSize: "20px",
                                                color: "#e59125",
                                              }}
                                              className="absolute-position"
                                            />
                                          </Button>
                                        </Tooltip>
                                        <Tooltip
                                          anchorElement="target"
                                          position="top"
                                          parentTitle={true}
                                        >
                                          <Button
                                            type="button"
                                            fillMode="flat"
                                            themeColor="primary"
                                            size="small"
                                            title={"Edit"}
                                            disabled={loading}
                                            onClick={() =>
                                              handleButtonClick(
                                                e?.DocumentID,
                                                fieldArrayRenderProps?.value[
                                                  innerIndex
                                                ]?.ID
                                              )
                                            }
                                            style={{
                                              width: 35,
                                              height: 35,
                                              position: "relative",
                                            }}
                                          >
                                            <FiEdit
                                              style={{
                                                fontSize: "20px",
                                                color: "green",
                                              }}
                                              className="absolute-position"
                                            />
                                          </Button>
                                        </Tooltip>
                                        <Tooltip
                                          anchorElement="target"
                                          position="top"
                                          parentTitle={true}
                                        >
                                          <Button
                                            type="button"
                                            fillMode="flat"
                                            themeColor="primary"
                                            title={"Delete"}
                                            size="small"
                                            disabled={loading}
                                            onClick={() =>
                                              handleDeleteDoc(e?.ID)
                                            }
                                            style={{
                                              width: 35,
                                              height: 35,
                                              position: "relative",
                                            }}
                                          >
                                            <MdDelete
                                              style={{ fontSize: "20px" }}
                                              className="absolute-position"
                                            />
                                          </Button>
                                        </Tooltip>
                                      </td>
                                    ) : (
                                      <td
                                        style={{
                                          padding: "0 0 10px 10px",
                                          display: "flex",
                                          gap: 10,
                                        }}
                                      >
                                        <Tooltip
                                          anchorElement="target"
                                          position="top"
                                          parentTitle={true}
                                        >
                                          <Button
                                            type="button"
                                            fillMode="flat"
                                            themeColor="primary"
                                            size="small"
                                            title={"Add"}
                                            disabled={loading}
                                            onClick={() =>
                                              handleButtonClick(
                                                e?.DocumentID,
                                                fieldArrayRenderProps?.value[
                                                  innerIndex
                                                ]?.ID
                                              )
                                            }
                                            style={{
                                              width: 35,
                                              height: 35,
                                              position: "relative",
                                            }}
                                          >
                                            <RiAddBoxFill
                                              style={{
                                                fontSize: "20px",
                                                color: "blue",
                                              }}
                                              className="absolute-position"
                                            />
                                          </Button>
                                        </Tooltip>
                                      </td>
                                    )}
                                  </tr>
                                )
                            )}
                          </table>
                        </GridLayoutItem>
                      </GridLayout>
                    )}
                </Card>
              </GridLayoutItem>
            )
          )}
      </GridLayout>
    </>
  );
};

const DeleteTourBookingDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TourBookingID = location.state?.TourBookingID;
  const docdeleteIndex = useAppSelector(
    (state) => state.tourBooking.docdeleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteTouristDocument(ID));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(getByIDTourBookingStep2(TourBookingID));
        dispatch(closeDialog());
        dispatch(clearTourTouristDocDeleteIndex());
      } else {
        dispatch(getByIDTourBookingStep2(TourBookingID));
        dispatch(closeDialog());
        dispatch(clearTourTouristDocDeleteIndex());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteTouristDocument" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Document"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Document?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(docdeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default TouristPaxRoomArray;
