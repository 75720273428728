import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
} from "@progress/kendo-react-form";
import React from "react";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import { MdDelete } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import IconButton from "../../../components/common/IconButton";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { IPaxType } from "../../paxtype/paxTypeModel";
import TouristPaxRoomArray from "./TouristPaxRoomArray";
import { setTourBookingDeleteIndex } from "../tourBookingSlice";
import { getGetPaxTourCostWithName } from "../services/tourBooking.services";
import moment from "moment";
import { useLocation } from "react-router-dom";

const TouristPaxDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TourBookingID = location.state?.TourBookingID;
  const PaxDetailDeleteIndex = useAppSelector(
    (state) => state.tourBooking.deleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const PaxTypeList = useAppSelector((state) => state.paxType.PaxTypeList);
  const TourBookingStep1Detail = useAppSelector(
    (state) => state.tourBooking.TourBookingStep1Detail
  );
  const name = fieldArrayRenderProps.name || "";

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("paxDeleteDialog"));
    dispatch(setTourBookingDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setTourBookingDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        RoomNo: null,
        PaxTypeID: null,
        TouristPaxInfo: [],
      },
    });
  }, [fieldArrayRenderProps]);

  const handleChangePaxType = async (e: any, index: number) => {
    const payload = {
      TourID: TourBookingStep1Detail?.TourID,
      TourBookingID: TourBookingID,
      TourCategoryID: TourBookingStep1Detail?.TourCategoryID,
      RateNoOfNights: TourBookingStep1Detail?.NoOfNights,
      PaxTypeID: e.value,
      RoomNo: index + 1,
      TourDate: TourBookingStep1Detail?.TourDate,
    };

    const res = await dispatch(getGetPaxTourCostWithName(payload));
    if (res.meta.requestStatus === "fulfilled") {
      const paxinfoarray = JSON.parse(JSON.stringify(res.payload))?.map(
        (e: any) => ({
          ...e,
          DOB: e.DOB ? moment(e.DOB).toDate() : "",
          ExpiryDate: e.ExpiryDate ? moment(e.ExpiryDate).toDate() : "",
        })
      );
      fieldArrayRenderProps.formRenderProps.onChange(
        `RoomPaxes.${index}.TouristPaxInfo`,
        {
          value: paxinfoarray,
        }
      );
    }
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {!fieldArrayRenderProps.value ? pushElementInarray() : null}
      <GridLayoutItem style={{ marginBottom: 10 }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Room
        </Button>
      </GridLayoutItem>
      {dialogName === "paxDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Pax Details"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(PaxDetailDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((RoomPaxes: any, index: number) => {
          return (
            <GridLayoutItem key={index}>
              <Card className="fieldarray-card">
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ rows: 0, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem>
                    <Button
                      themeColor="primary"
                      fillMode={"solid"}
                      type="button"
                      disabled
                      size={"small"}
                      rounded={"full"}
                    >
                      Room {index + 1}
                    </Button>
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={2}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      size={"small"}
                      fillMode={"solid"}
                      type="button"
                      style={{ height: 24, width: 24 }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`${name}.${index}.PaxTypeID`}
                      label="Pax Type"
                      component={FormSelectionField}
                      options={PaxTypeList?.map((paxtype: IPaxType) => {
                        return {
                          value: paxtype?.ID,
                          label: paxtype?.PaxType,
                        };
                      })}
                      onChange={(e) => handleChangePaxType(e, index)}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={2}></GridLayoutItem>
                  <GridLayoutItem colSpan={3} style={{ marginTop: 20 }}>
                    <FieldArray
                      formRenderProps={fieldArrayRenderProps.formRenderProps}
                      component={TouristPaxRoomArray}
                      name={`${name}.${index}.TouristPaxInfo`}
                    />
                  </GridLayoutItem>
                </GridLayout>
              </Card>
            </GridLayoutItem>
          );
        })}
    </GridLayout>
  );
};

export default TouristPaxDetailsArray;
