import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { setDocumentDetailsDeleteIndex } from "./sectorSlice";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { IDocument } from "../document/documentModel";
import FormSelectionField from "../../components/formFields/FormSelectionField";

const DocumentDetailsArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const SectorID = location.state?.SectorID;
  const dialogName = useAppSelector((state) => state?.dialog?.dialogName);
  const DocumnetList = useAppSelector((state) => state?.document?.DocumentList);
  const DocumentDetailsDeleteIndex = useAppSelector(
    (state) => state.sector.DocumentDetailsDeleteIndex
  );

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("documentDetailsDeleteDialog"));
    dispatch(setDocumentDetailsDeleteIndex(index));
  };

  useEffect(() => {
    if (!SectorID || fieldArrayRenderProps?.value?.length < 1) {
      pushElementInarray();
    }
  }, []);

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setDocumentDetailsDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        DocumentID: 0,
        PositionNo: 0,
      },
    });
  }, [fieldArrayRenderProps]);

  return (
    <>
      <GridLayout>
        <GridLayoutItem
          className="bg-dark text-white fw-600"
          style={{ width: "100%" }}
        >
          <GridLayout
            style={{
              marginRight: 40,
              padding: 10,
            }}
            gap={{ rows: 10, cols: 10 }}
            cols={[
              { width: "10%" },
              { width: "40%" },
              { width: "40%" },
              { width: "10%" },
            ]}
          >
            <GridLayoutItem>SR No</GridLayoutItem>
            <GridLayoutItem>Document Name</GridLayoutItem>
            <GridLayoutItem>Position No</GridLayoutItem>
            <GridLayoutItem>Action</GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
        {dialogName === "documentDetailsDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Document Details"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <DialogCloseButton themeColor="error" />
                  <Button
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() =>
                      handleDeleteAction(DocumentDetailsDeleteIndex)
                    }
                    type="button"
                  >
                    Delete
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map((_: any, index: number) => (
            <GridLayoutItem key={index} style={{ width: "100%" }}>
              <GridLayout
                style={{ marginRight: 40, padding: 10 }}
                gap={{ rows: 10, cols: 10 }}
                cols={[
                  { width: "10%" },
                  { width: "40%" },
                  { width: "40%" },
                  { width: "10%" },
                ]}
              >
                <GridLayoutItem>
                  <h5>{index + 1}</h5>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"DocumentID"}
                    wrapperStyle={{ margin: 0 }}
                    name={`DocumentDetails.${index}.DocumentID`}
                    component={FormSelectionField}
                    options={DocumnetList?.map((documnet: IDocument) => {
                      return {
                        value: documnet?.ID,
                        label: documnet?.DocumentName,
                      };
                    })}
                    placeholder="Document Name"
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"PositionNo"}
                    type="number"
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`DocumentDetails.${index}.PositionNo`}
                    placeholder="Position No"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    position: "relative",
                  }}
                >
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{
                      position: "absolute",
                      height: 32,
                      width: 32,
                      top: 0,
                      right: 0,
                    }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
              </GridLayout>
            </GridLayoutItem>
          ))}
      </GridLayout>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{ marginTop: 10, borderTop: "1px solid lightgray" }}
        >
          <GridLayout
            style={{ marginRight: 45, padding: 10 }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "20%" },
              { width: "20%" },
              { width: "20%" },
              { width: "20%" },
              { width: "20%" },
            ]}
          >
            <GridLayoutItem className="d-flex align-items-end">
              <Button
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={pushElementInarray}
                type="button"
              >
                Add Document
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default DocumentDetailsArray;
