import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISightseen, ISightseenInitialState } from "./sightseenModel";
import {
  getAllSightseen,
  createSightseen,
  updateSightseen,
  deleteSightseen,
  getSightseenByID,
  getAllActiveSightseen,
  ActiveInactiveSightseen,
} from "./services/sightseen.services";

const initialState: ISightseenInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  SightseenList: [],
  SightseenID: null,
  SightseenDetail: {
    ID: null,
    SectorId: null,
    SightseenName: "",
    Rate: null,
    IsActive: 1,
  },
};

const sightseenSlice = createSlice({
  name: "sightseen",
  initialState,
  reducers: {
    clearSightseenDetails: (state) => {
      state.SightseenDetail = initialState.SightseenDetail;
    },
    setSightseenID: (state, action) => {
      state.SightseenID = action.payload;
    },
    clearSightseenID: (state) => {
      state.SightseenID = initialState.SightseenID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSightseen.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSightseen.fulfilled,
      (state, action: PayloadAction<ISightseen[]>) => {
        state.loading = false;
        state.SightseenList = action.payload || [];
      }
    );
    builder.addCase(getAllSightseen.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SightseenList = [];
    });
    builder.addCase(createSightseen.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createSightseen.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createSightseen.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateSightseen.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateSightseen.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateSightseen.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteSightseen.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteSightseen.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteSightseen.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getSightseenByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getSightseenByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.SightseenDetail = action.payload;
    });
    builder.addCase(getSightseenByID.rejected, (state, action) => {
      state.formLoading = false;
      state.SightseenDetail = initialState.SightseenDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveSightseen.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveSightseen.fulfilled,
      (state, action: PayloadAction<ISightseen[]>) => {
        state.loading = false;
        state.SightseenList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveSightseen.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SightseenList = [];
    });
    builder.addCase(ActiveInactiveSightseen.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveSightseen.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveSightseen.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearSightseenDetails, clearSightseenID, setSightseenID } =
  sightseenSlice.actions;
export default sightseenSlice.reducer;
