// import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../components/layout/Layout";
import { Button } from "@progress/kendo-react-buttons";
import LogIn from "../features/login/Login";
import { SuccessToast } from "../components/toast/Toasts";
import Country from "../features/country/Country";
import User from "../features/user/User";
import CreateUser from "../features/user/CreateUser";
import UserType from "../features/userType/UserType";
import CreateUserType from "../features/userType/CreateUserType";
import Rights from "../features/rights/Rights";
import Menu from "../features/menu/Menu";
import City from "../features/city/City";
import State from "../features/state/State";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../features/dashboard/Dashboard";
import VendorType from "../features/vendortype/VendorType";
import Region from "../features/region/Region";
import RoomType from "../features/roomtype/RoomType";
import MealType from "../features/mealtype/MealType";
import SpecialityType from "../features/specialitytype/SpecialityType";
import Company from "../features/company/Company";
import CreateCompany from "../features/company/CreateCompany";
import Currency from "../features/currency/Currency";
import Airline from "../features/airline/Airline";
import CreateAirline from "../features/airline/CreateAirline";
import Airport from "../features/airport/Airport";
import AirlineClass from "../features/airlineclass/AirlineClass";
import VehicleSharingPax from "../features/vehiclesharingpax/VehicleSharingPax";
import FlightPlace from "../features/flightplace/FlightPlace";
import PaxType from "../features/paxtype/PaxType";
import Branch from "../features/branch/Branch";
import CreateBranch from "../features/branch/CreateBranch";
import RoomCategory from "../features/roomcategory/RoomCategory";
import HotelMapping from "../features/hotelmapping/HotelMapping";
import RouteMaster from "../features/route/Route";
import Tour from "../features/tour/Tour";
import CreateTour from "../features/tour/CreateTour";
import Gst from "../features/gst/Gst";
import CreateGst from "../features/gst/CreateGst";
import NewLogin from "../features/login/NewLogin";
import VehicleType from "../features/vehicleType/VehicleType";
import Bank from "../features/bank/Bank";
import CreateBank from "../features/bank/CreateBank";
import Sightseen from "../features/sightseen/Sightseen";
import Vendors from "../features/vendors/Vendors";
import CreateVendors from "../features/vendors/CreateVendors";
import Document from "../features/document/Document";
import Sector from "../features/sector/Sector";
import CreateSector from "../features/sector/CreateSector";
import Itinerary from "../features/itinerary/Itinerary";
import Hotel from "../features/hotel/Hotel";
import CreateHotel from "../features/hotel/CreateHotel";
import Nationality from "../features/nationality/Nationality";
import FoodOption from "../features/foodoption/FoodOption";
import TourBooking from "../features/tourbooking/TourBooking";
import CreateTourBooking from "../features/tourbooking/CreateTourBooking";
import TourBookingCancellation from "../features/tourbooking/tourbookingforms/TourBookingCancellation";

const MainRouter = () => {
  return (
    <Routes>
      <Route path="login" element={<LogIn />} />
      <Route path="newlogin" element={<NewLogin />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        <Route index={true} element={<Dashboard />} />
        <Route path="menu" element={<Menu />} />
        <Route path="rights" element={<Rights />} />
        <Route path="user" element={<User />} />
        <Route path="user/create" element={<CreateUser />} />
        <Route path="user/edit" element={<CreateUser />} />
        <Route path="usertype" element={<UserType />} />
        <Route path="usertype/create" element={<CreateUserType />} />
        <Route path="usertype/edit" element={<CreateUserType />} />
        <Route path="country" element={<Country />} />
        <Route path="state" element={<State />} />
        <Route path="city" element={<City />} />
        <Route path="nationality" element={<Nationality />} />
        <Route path="foodoption" element={<FoodOption />} />
        <Route path="region" element={<Region />} />
        <Route path="tourcategory" element={<RoomType />} />
        <Route path="mealtype" element={<MealType />} />
        <Route path="vendortype" element={<VendorType />} />
        <Route path="vehicletype" element={<VehicleType />} />
        <Route path="specialitytype" element={<SpecialityType />} />
        <Route path="company" element={<Company />} />
        <Route path="company/create" element={<CreateCompany />} />
        <Route path="company/edit" element={<CreateCompany />} />
        <Route path="currency" element={<Currency />} />
        <Route path="airline" element={<Airline />} />
        <Route path="airline/create" element={<CreateAirline />} />
        <Route path="airline/edit" element={<CreateAirline />} />
        <Route path="airport" element={<Airport />} />
        <Route path="airlineclass" element={<AirlineClass />} />
        <Route path="vehiclesharingpax" element={<VehicleSharingPax />} />
        <Route path="flightplace" element={<FlightPlace />} />
        <Route path="paxtype" element={<PaxType />} />
        <Route path="route" element={<RouteMaster />} />
        <Route path="roomcategory" element={<RoomCategory />} />
        <Route path="hotelmapping" element={<HotelMapping />} />
        <Route path="branch" element={<Branch />} />
        <Route path="branch/create" element={<CreateBranch />} />
        <Route path="branch/edit" element={<CreateBranch />} />
        <Route path="itinerary" element={<Itinerary />} />
        <Route path="vendors" element={<Vendors />} />
        <Route path="vendors/create" element={<CreateVendors />} />
        <Route path="vendors/edit" element={<CreateVendors />} />
        <Route path="sector" element={<Sector />} />
        <Route path="sector/create" element={<CreateSector />} />
        <Route path="sector/edit" element={<CreateSector />} />
        <Route path="sightseen" element={<Sightseen />} />
        <Route path="document" element={<Document />} />
        <Route path="bank" element={<Bank />} />
        <Route path="bank/create" element={<CreateBank />} />
        <Route path="bank/edit" element={<CreateBank />} />
        <Route path="gst" element={<Gst />} />
        <Route path="gst/create" element={<CreateGst />} />
        <Route path="gst/edit" element={<CreateGst />} />
        <Route path="tour" element={<Tour />} />
        <Route path="tour/create" element={<CreateTour />} />
        <Route path="tour/edit" element={<CreateTour />} />
        <Route path="tourbooking" element={<TourBooking />} />
        <Route path="tourbooking/create" element={<CreateTourBooking />} />
        <Route path="tourbooking/edit" element={<CreateTourBooking />} />
        <Route
          path="tourbooking/cancellation"
          element={<TourBookingCancellation />}
        />
        <Route path="hotel" element={<Hotel />} />
        <Route path="hotel/create" element={<CreateHotel />} />
        <Route path="hotel/edit" element={<CreateHotel />} />
        <Route
          path="about"
          element={
            <Button
              fillMode={"solid"}
              themeColor={"primary"}
              onClick={() => SuccessToast("Hello")}
            >
              Hello
            </Button>
          }
        />
        <Route path="*" element={<h1>404</h1>} />
      </Route>
    </Routes>
  );
};

export default MainRouter;
