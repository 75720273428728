import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IRoute } from "../routeModel";

export const getAllRoutes = createAsyncThunk("Route/FindAllRoute", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/Route/FindAllRoute`,
      {
        RouteName: "",
      }
    );
    return response.data?.Data;
  } catch (error: any) {
    // ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching Routes:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createRoute = createAsyncThunk(
  "Route/InsertRoute",
  async (RouteData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Route/InsertRoute`,
        RouteData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating route:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateRoute = createAsyncThunk(
  "Route/UpdateRoute",
  async (RouteData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Route/UpdateRoute`,
        RouteData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating route:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteRoute = createAsyncThunk(
  "Route/DeleteRoute",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Route/DeleteRoute`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting route:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getRouteByID = createAsyncThunk(
  "Route/FindByIDRoute",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Route/FindByIDRoute`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        SectorID: result.SectorID,
        RouteName: result.RouteName,
        Description: result.Description,
        IsActive: result.IsActive ? 1 : 2,
      } as IRoute;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching route:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveRoutes = createAsyncThunk(
  "Route/FindAllActiveRoute",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Route/FindAllActiveRoute`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Routes:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveRoute = createAsyncThunk(
  "Route/ActiveInActiveRoute",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Route/ActiveInActiveRoute`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive route:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
