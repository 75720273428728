import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import {
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import RippleButton from "../../../components/common/RippleButton";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import {
  clearTourBookingStep3Details,
  decreaseTourBookingActiveStep,
  increaseTourBookingActiveStep,
} from "../tourBookingSlice";
import PlaceInfoArray from "./PlaceInfoArray";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  getByIDTourBookingStep3,
  insertTourBookingStep3,
} from "../services/tourBooking.services";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const PlaceDatechangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const TourPlaceBooking = formRenderProps.valueGetter("TourPlaceBooking");

  useEffect(() => {
    if (TourPlaceBooking && TourPlaceBooking.length > 0) {
      TourPlaceBooking.map((item: any, index: number) => {
        const CheckOutDate = item.CheckinDate
          ? moment(item.CheckinDate)
              .add(+item?.NoOfNights || 0, "days")
              .toDate()
          : "";
        formRenderProps.onChange(`TourPlaceBooking.${index}.CheckOutDate`, {
          value: CheckOutDate || "",
        });
      });
    }
  }, [
    TourPlaceBooking.map((item: any) => item.NoOfNights).join("-"),
    TourPlaceBooking.map((item: any) => item.CheckinDate).join("-"),
  ]);
  return null;
};

const TourPlaces = () => {
  const location = useLocation();
  const TourBookingID = location.state?.TourBookingID;
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.tourBooking.loading);
  const TourBookingStep3Detail = useAppSelector(
    (state) => state.tourBooking.TourBookingStep3Detail
  );

  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    return () => {
      dispatch(clearTourBookingStep3Details());
    };
  }, []);

  useEffect(() => {
    if (TourBookingID) {
      dispatch(getByIDTourBookingStep3(TourBookingID));
    }
  }, [TourBookingID]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [TourBookingStep3Detail]);

  const handleSubmit = async (values: any) => {
    if (TourBookingID) {
      const insertPayload = {
        TourBookingID: TourBookingID,
        TourPlaceBooking: values.TourPlaceBooking
          ? values.TourPlaceBooking.map((tourplace: any) => {
              return {
                TourID: tourplace.TourID ? tourplace.TourID : null,
                TourBookingID: tourplace.TourBookingID
                  ? tourplace.TourBookingID
                  : null,
                PlaceID: tourplace.PlaceID ? tourplace.PlaceID : null,
                CheckinDate: tourplace.CheckinDate
                  ? moment(tourplace.CheckinDate).format("YYYY-MM-DD")
                  : "",
                NoOfNights: tourplace.NoOfNights ? tourplace.NoOfNights : null,
                CheckOutDate: tourplace.CheckOutDate
                  ? moment(tourplace.CheckOutDate).format("YYYY-MM-DD")
                  : "",
                Position: tourplace.Position ? +tourplace.Position : null,
                HotelID: tourplace.HotelID ? +tourplace.HotelID : null,
              };
            })
          : [],
      };
      const response = await dispatch(insertTourBookingStep3(insertPayload));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(increaseTourBookingActiveStep());
      }
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={TourBookingStep3Detail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout gap={{ rows: 0, cols: 10 }} cols={[{ width: "100%" }]}>
              <GridLayoutItem>
                <Typography.h4>{"Place Information"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem style={{ marginTop: 10 }}>
                <FieldArray
                  formRenderProps={formRenderProps}
                  component={PlaceInfoArray}
                  name="TourPlaceBooking"
                />
              </GridLayoutItem>
              <PlaceDatechangeWatcher formRenderProps={formRenderProps} />
              <GridLayoutItem
                style={{
                  textAlign: "end",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <RippleButton
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 4 }}
                  onClick={() => dispatch(decreaseTourBookingActiveStep())}
                >
                  Cancel
                </RippleButton>
                <ButtonWithLoading
                  label={"Save"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
        </FormElement>
      )}
    />
  );
};

export default TourPlaces;
