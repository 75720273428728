import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL } from "../../../app/config";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IUserDetails } from "../userModel";

export const getAllUsers = createAsyncThunk("User/FindAllUser", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/User/FindAllUser`,
      {
        FirstName: "",
      }
    );
    return response.data.Data;
  } catch (error: any) {
    // ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error fetching users:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createUser = createAsyncThunk(
  "User/InsertUser",
  async (UserData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/InsertUser`,
        UserData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateUser = createAsyncThunk(
  "User/UpdateUser",
  async (UserData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateUser`,
        UserData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteUser = createAsyncThunk(
  "User/DeleteUser",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/DeleteUser`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getUserByID = createAsyncThunk(
  "User/FindByIDUser",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByIDUser`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        FirstName: result.FirstName,
        LastName: result.LastName,
        MobileNo: result.MobileNo,
        AlternateMobileNo: result.AlternateMobileNo,
        EmailID: result.EmailID,
        AlternateEmailID: result.AlternateEmailID,
        Password: result.Password,
        IsActive: result.IsActive === true ? 1 : 2,
        UserTypeID: result.UserTypeID,
        ...result?.UserRightsAssign?.reduce(
          (acc: any, { MenuID, RightsID }: { MenuID: any; RightsID: any }) => {
            if (!RightsID) {
              acc[MenuID] = true;
            } else {
              acc[`${MenuID}Rights`] = RightsID.split(",").map(Number);
              acc[MenuID] = true;
            }
            return acc;
          },
          {}
        ),
      } as IUserDetails;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const activeInActiveUsers = createAsyncThunk(
  "User/ActiveInActiveUser",
  async (Data: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/ActiveInActiveUser`,
        Data
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error in status change:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveUsers = createAsyncThunk(
  "User/FindAllActiveUser",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/User/FindAllActiveUser`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Users:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

