import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getTourBookingCancellationViewByID,
  tourBookingCancellation,
} from "../services/tourBooking.services";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { LoadingPanel } from "../../../components/layout/Loading";
import ShadowCard from "../../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { getter, Typography } from "@progress/kendo-react-common";
import FormTextField from "../../../components/formFields/FormTextField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import {
  Grid,
  GridColumn,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  //   GridFilterChangeEvent,
  GridHeaderSelectionChangeEvent,
  GridPageChangeEvent,
  GridSelectionChangeEvent,
} from "@progress/kendo-react-grid";
// import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import {
  getSelectedState,
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { ErrorToast } from "../../../components/toast/Toasts";

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const TourBookingCancellation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const TourBookingID = location.state?.TourBookingID;
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getTourBookingCancellationViewByID({ TourBookingID }));
  }, [TourBookingID]);

  const loading = useAppSelector((state) => state.tourBooking.loading);
  const TourBookingCancellationDetails = useAppSelector(
    (state) => state.tourBooking.TourBookingCancellationDetails
  );

  const [rowSelectionModel, setRowSelectionModel] = React.useState<number[]>(
    []
  );
  const dataState = TourBookingCancellationDetails?.PaxList?.map(
    (dataItem: any) => Object.assign({ selected: false }, dataItem)
  );
  const [selectedState, setSelectedState] = React.useState<{
    [id: string]: boolean | number[];
  }>({});

  const DATA_ITEM_KEY: string = "ID";
  const SELECTED_FIELD: string = "selected";
  const idGetter = getter(DATA_ITEM_KEY);

  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? TourBookingCancellationDetails?.PaxList?.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const onSelectionChange = React.useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
      const trueKeys = Object.keys(newSelectedState)
        .filter((key) => newSelectedState[key])
        .map(Number);
      setRowSelectionModel(trueKeys);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = React.useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = event.syntheticEvent.target;
      const checked = checkboxElement.checked;
      const newSelectedState: { [key: string]: boolean } = {};

      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
      const trueKeys = Object.keys(newSelectedState)
        .filter((key: string) => newSelectedState[key])
        .map(Number);
      setRowSelectionModel(trueKeys);
    },
    []
  );

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const handleSubmit = async (values: any) => {
    if (rowSelectionModel?.length < 1) {
      return ErrorToast("Please select atleast one pax for cancellation");
    }

    try {
      if (TourBookingID) {
        const payload = {
          ID: TourBookingID,
          TourBookingPaxID: rowSelectionModel?.join(", "),
          TourCancellationCost: +values?.TourCancellationCost,
          TourCancellationRemarks: values?.TourCancellationRemarks,
          NoOfRooms: +values?.NoOfRooms,
          TotalAdults: +values?.TotalAdults,
          TotalCWB: +values?.TotalCWB,
          TotalCNB: +values?.TotalCNB,
        };

        const response = await dispatch(tourBookingCancellation(payload));
        if (response.meta.requestStatus === "fulfilled") {
          navigate("/tourbooking");
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <ShadowCard style={{ padding: 12 }}>
        <GridLayout
          style={{ marginRight: 20 }}
          gap={{ rows: 5, cols: 10 }}
          cols={[{ width: "33.33%" }, { width: "33.33%" }, { width: "33.33%" }]}
        >
          <GridLayoutItem colSpan={3}>
            <Typography.h4>{"Tour Booking Cancellation"}</Typography.h4>
          </GridLayoutItem>
          <GridLayoutItem colSpan={3}>
            <Typography.h5>
              {"Booking No : "}
              <span style={{ color: "green" }}>
                {TourBookingCancellationDetails?.TourBookingNo}
              </span>
            </Typography.h5>
          </GridLayoutItem>
          <GridLayoutItem>
            <Typography.h6>
              {"Passenger Name : "}{" "}
              <span style={{ color: "gray" }}>
                {TourBookingCancellationDetails?.Name}
              </span>
            </Typography.h6>
          </GridLayoutItem>
          <GridLayoutItem colSpan={2}>
            <Typography.h6>
              {"Mo : "}{" "}
              <span style={{ color: "gray" }}>
                {TourBookingCancellationDetails?.MobileNo}
              </span>
            </Typography.h6>
          </GridLayoutItem>
          <GridLayoutItem colSpan={3}>
            <Typography.h6>
              {"Tour Name : "}
              <span style={{ color: "gray" }}>
                {TourBookingCancellationDetails?.TourName}
              </span>
            </Typography.h6>
          </GridLayoutItem>
          <GridLayoutItem colSpan={3}>
            <Typography.h6>
              {"Tour Date : "}
              <span style={{ color: "gray" }}>
                {TourBookingCancellationDetails?.TourStartDate}
              </span>
              {" - "}
              <span style={{ color: "gray" }}>
                {TourBookingCancellationDetails?.TourEndDate}
              </span>
            </Typography.h6>
          </GridLayoutItem>
          {/* <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                }}
              >
                <div>
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginRight: 4 }}
                    // onClick={() => navigate("/airline")}
                  >
                    Cancel
                  </Button>
                  <ButtonWithLoading
                    label={TourBookingID ? "Update" : "Create"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </div>
              </GridLayoutItem> */}
        </GridLayout>
      </ShadowCard>
      <ShadowCard style={{ padding: 12, marginTop: 10 }}>
        <GridLayout
          style={{ marginRight: 20 }}
          gap={{ rows: 5, cols: 10 }}
          cols={[{ width: "33.33%" }, { width: "33.33%" }, { width: "33.33%" }]}
        >
          <GridLayoutItem
            style={{
              maxHeight: "calc(100vh - 152px)",
              width: "100%",
            }}
            colSpan={3}
          >
            <Grid
              style={{
                maxHeight: "calc(100vh - 152px)",
              }}
              data={TourBookingCancellationDetails?.PaxList?.map(
                (item: any) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                })
              )}
              skip={page.skip}
              take={page.take}
              total={TourBookingCancellationDetails?.PaxList?.length}
              pageable={{
                buttonCount: 5,
                pageSizes: [5, 10, 15, "All"],
                pageSizeValue: pageSizeValue,
                type: "input",
              }}
              onPageChange={pageChange}
              dataItemKey={DATA_ITEM_KEY}
              selectedField={SELECTED_FIELD}
              selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "multiple",
              }}
              onSelectionChange={onSelectionChange}
              onHeaderSelectionChange={onHeaderSelectionChange}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <GridColumn
                filterable={false}
                field={SELECTED_FIELD}
                width={50}
                headerSelectionValue={
                  dataState?.findIndex(
                    (item: any) => !selectedState[idGetter(item)]
                  ) === -1
                }
              />
              <GridColumn field="Name" title="Name" width={300} />
              <GridColumn field="Gender" title="Gender" />
              <GridColumn field="Age" title="Age" />
              <GridColumn field="RoomNo" title="Room No" />
              <GridColumn field="Amount" title="Amount" />
              <GridColumn field="ExtraCost" title="Extra Cost" />
              <GridColumn field="DiscountAmount" title="Disc. Amount" />
              <GridColumn field="GSTAmount" title="GST Amount" />
              <GridColumn field="FinalCost" title="Final Cost" />
            </Grid>
          </GridLayoutItem>
        </GridLayout>
      </ShadowCard>
      <Form
        onSubmit={handleSubmit}
        initialValues={TourBookingCancellationDetails}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 5, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem>
                  <Field
                    name="NoOfRooms"
                    label="No Of Rooms"
                    type="number"
                    validator={requiredValidator}
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="TotalAdults"
                    label="Total Adults"
                    type="number"
                    validator={requiredValidator}
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="TotalCWB"
                    label="Total CWB"
                    type="number"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="TotalCNB"
                    label="Total CNB"
                    type="number"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="TourCancellationCost"
                    label="Cancellation Amount"
                    type="number"
                    validator={requiredValidator}
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}></GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="TourCancellationRemarks"
                    label="Remarks"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}></GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                  colSpan={4}
                >
                  <div>
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginRight: 4 }}
                      onClick={() => navigate("/tourbooking")}
                    >
                      Cancel
                    </Button>
                    <ButtonWithLoading
                      label={"Confirm"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default TourBookingCancellation;
