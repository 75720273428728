import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ISightseen } from "../sightseenModel";

export const getAllSightseen = createAsyncThunk(
  "Sightseen/FindAllSightseen",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sightseen/FindAllSightseen`,
        {
          SightseenName: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sightseen:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createSightseen = createAsyncThunk(
  "Sightseen/InsertSightseen",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sightseen/InsertSightseen`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Sightseen:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateSightseen = createAsyncThunk(
  "Sightseen/UpdateSightseen",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sightseen/UpdateSightseen`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating sightseen:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteSightseen = createAsyncThunk(
  "Sightseen/DeleteSightseen",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sightseen/DeleteSightseen`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting sightseen:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSightseenByID = createAsyncThunk(
  "Sightseen/FindByIDSightseen",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sightseen/FindByIDSightseen`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        SectorId: result.SectorId,
        SectorName:result.SectorName,
        SightseenName: result.SightseenName,
        Rate: result.Rate,
        IsActive: result.IsActive === true ? 1 : 2,
      } as ISightseen;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Sightseen:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveSightseen = createAsyncThunk(
  "Sightseen/FindAllActiveSightseen",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Sightseen/FindAllActiveSightseen`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sightseen:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveSightseen = createAsyncThunk(
  "Sightseen/ActiveInActiveSightseen",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sightseen/ActiveInActiveSightseen`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Sightseen:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
