import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
// import FormImageUpload from "../../components/formFields/FormImageUpload";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import FormCheckbox from "../../components/formFields/FormCheckbox";
import { createUser, getUserByID, updateUser } from "./services/user.services";
import { clearUserDetails } from "./userSlice";
import ShadowCard from "../../components/common/ShadowCard";
import FormMultiSelectionFiled from "../../components/formFields/FormMultiSelectionFiled";
import { IRights } from "../rights/rightsModel";
import {
  getAllActiveMenuSubMenuList,
  getAllActiveMenus,
} from "../menu/services/menu.services";
import { getAllActiveRights } from "../rights/services/rights.services";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import {
  getAllActiveUserTypes,
  getUserTypeByID,
} from "../userType/services/userType.services";
import { IUserType } from "../userType/userTypeModel";
import { STATUSARRAY } from "../../_contstants/common";
import FormPasswordField from "../../components/formFields/FormPasswordField";

const CreateUser: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const UserID = location.state?.UserID;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  const [formKey, setFormKey] = React.useState(1);

  const loading = useAppSelector((state) => state.user.loading);
  const UserDetails = useAppSelector((state) => state.user.UserDetails);
  const MenuSubMenuList = useAppSelector((state) => state.menu.MenuSubMenuList);
  const MenuList = useAppSelector((state) => state.menu.MenuList);
  const RightsList = useAppSelector((state) => state.rights.RightsList);
  const UserTypeList = useAppSelector((state) => state.userType.UserTypeList);

  useEffect(() => {
    if (UserID) {
      dispatch(getUserByID(UserID));
    }
  }, [UserID]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [UserDetails]);

  useEffect(() => {
    dispatch(getAllActiveMenuSubMenuList());
    dispatch(getAllActiveMenus());
    dispatch(getAllActiveRights());
    dispatch(getAllActiveUserTypes());

    return () => {
      dispatch(clearUserDetails());
    };
  }, []);

  const handleMenuChange = (
    formRenderProps: FormRenderProps,
    menuName: string,
    isMenuChecked: boolean
  ) => {
    MenuSubMenuList &&
      MenuSubMenuList.length > 0 &&
      MenuSubMenuList?.forEach((menu: any) => {
        if (isMenuChecked === false) {
          formRenderProps.onChange(`${menu?.ID}Rights`, {
            value: null,
          });
        }

        if (menu?.ID === menuName) {
          menu?.MenuSubList?.forEach((submenu: any) => {
            formRenderProps.onChange(`${submenu?.ID}`, {
              value: isMenuChecked,
            });
            if (isMenuChecked === false) {
              formRenderProps.onChange(`${submenu?.ID}Rights`, {
                value: null,
              });
            }
          });
        }
      });
  };

  const handleSubMenuChange = (
    formRenderProps: FormRenderProps,
    subMenuName: string,
    isMenuChecked: boolean
  ) => {
    MenuSubMenuList &&
      MenuSubMenuList.length > 0 &&
      MenuSubMenuList?.map((menu: any) => {
        menu?.MenuSubList?.map((submenu: any) => {
          if (submenu?.ID === subMenuName) {
            if (isMenuChecked === false) {
              formRenderProps.onChange(`${submenu?.ID}Rights`, {
                value: null,
              });
            }
            if (submenu?.ID && submenu?.UnderMenuID) {
              formRenderProps.onChange(`${submenu?.UnderMenuID}`, {
                value: true,
              });
            }
          }
        });
      });
  };

  const handleUserTypeChange = async (
    e: any,
    formRenderProps: FormRenderProps
  ) => {
    MenuList.map((e: any) => {
      formRenderProps.onChange(`${e.ID}`, { value: false });
      formRenderProps.onChange(`${e.ID}Rights`, { value: null });
    });
    if (e?.value) {
      const res = await dispatch(getUserTypeByID(e?.value));
      if (res.meta.requestStatus === "fulfilled") {
        for (const key in res?.payload) {
          formRenderProps.onChange(`${key}`, { value: res?.payload[key] });
        }
      } else {
        MenuList.map((e: any) => {
          formRenderProps.onChange(`${e.ID}`, { value: false });
          formRenderProps.onChange(`${e.ID}Rights`, { value: null });
        });
      }
    }
  };

  const handleSubmit = async (values: any) => {
    interface ResultItem {
      MenuID: number;
      RightsID: string;
    }

    const result: ResultItem[] = Object.entries(values).reduce(
      (acc: ResultItem[], [key, value]) => {
        if (key.endsWith("Rights")) {
          const menuID: number = parseInt(key.replace("Rights", ""), 10);
          if (value) {
            const rightsID: string =
              Array.isArray(value) && value.length > 0 ? value.join(", ") : "";
            acc.push({ MenuID: menuID, RightsID: rightsID });
          }
        } else if (value === true) {
          const menuID: number = parseInt(key, 10);
          if (!values[`${menuID}Rights`] && !isNaN(menuID)) {
            acc.push({ MenuID: menuID, RightsID: "" });
          }
        }
        return acc;
      },
      []
    );

    if (UserID) {
      const editPayload = {
        ID: UserID,
        FirstName: values.FirstName ? values.FirstName : "",
        LastName: values.LastName ? values.LastName : "",
        MobileNo: values.MobileNo ? values.MobileNo?.toString() : "",
        AlternateMobileNo: values.AlternateMobileNo
          ? values.AlternateMobileNo?.toString()
          : "",
        EmailID: values.EmailID ? values.EmailID : "",
        AlternateEmailID: values.AlternateEmailID
          ? values.AlternateEmailID
          : "",
        // Password: values.Password ? values.Password : "",
        IsActive: values.IsActive === 1,
        UserTypeID: values?.UserTypeID ? values?.UserTypeID : null,
        UserRightsAssign: result || [],
      };
      const response = await dispatch(updateUser(editPayload));
      if (response?.meta?.requestStatus === "fulfilled") {
        navigate(-1);
      }
    } else {
      const insertPayload = {
        FirstName: values.FirstName ? values.FirstName : "",
        LastName: values.LastName ? values.LastName : "",
        MobileNo: values.MobileNo ? values.MobileNo?.toString() : "",
        AlternateMobileNo: values.AlternateMobileNo
          ? values.AlternateMobileNo?.toString()
          : "",
        EmailID: values.EmailID ? values.EmailID : "",
        AlternateEmailID: values.AlternateEmailID
          ? values.AlternateEmailID
          : "",
        Password: values.Password ? values.Password : "",
        IsActive: values.IsActive === 1,
        UserTypeID: values?.UserTypeID ? values?.UserTypeID : null,
        UserRightsAssign: result || [],
      };
      const response = await dispatch(createUser(insertPayload));
      if (response?.meta?.requestStatus === "fulfilled") {
        navigate(-1);
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={UserDetails}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {UserID ? "Update User" : "Create User"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="FirstName"
                    maxLength="100"
                    label="First Name"
                    placeholder="i.e. John"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="LastName"
                    maxLength="100"
                    label="Last Name"
                    placeholder="i.e. Smith"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="MobileNo"
                    type="number"
                    max="10"
                    label="Mobile No"
                    placeholder="i.e. 9999999999"
                    validator={requiredValidator}
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="AlternateMobileNo"
                    type="number"
                    max="10"
                    placeholder="i.e. 9999999999"
                    label="Alternate Mobile No "
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="EmailID"
                    type="text"
                    label="Email"
                    placeholder="i.e. example@example.com"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="AlternateEmailID"
                    type="text"
                    label="Alternate Email"
                    placeholder="i.e. example@example.com"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                {!UserID && (
                  <GridLayoutItem>
                    <Field
                      name="Password"
                      type="Password"
                      label="Password"
                      placeholder="Password"
                      component={FormPasswordField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="UserTypeID"
                    label="User Type"
                    placeholder="i.e. Admin"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    onChange={(e: any) =>
                      handleUserTypeChange(e, formRenderProps)
                    }
                    options={UserTypeList?.map((usertype: IUserType) => {
                      return {
                        value: usertype?.ID,
                        label: usertype?.UserType,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="IsActive"
                    label="Status"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={STATUSARRAY?.map((status: any) => {
                      return {
                        value: status?.value,
                        label: status?.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                  <Field
                    name="UserImage"
                    type="file"
                    accept="image/*"
                    label="User Image"
                    component={FormImageUpload}
                  />
                </GridLayoutItem> */}
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>Rights</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  {MenuSubMenuList &&
                    MenuSubMenuList.length > 0 &&
                    MenuSubMenuList?.map((menu: any) => {
                      return (
                        <>
                          <div
                            style={{
                              display: "flex",
                              width: "50%",
                              justifyContent: "space-between",
                              alignItems: "center",
                              minHeight: 45,
                            }}
                          >
                            <Field
                              key={menu?.ID}
                              name={`${menu?.ID}`}
                              label={menu?.MenuName}
                              component={FormCheckbox}
                              onChange={() =>
                                handleMenuChange(
                                  formRenderProps,
                                  menu?.ID,
                                  formRenderProps.valueGetter(`${menu?.ID}`)
                                )
                              }
                            />
                            {formRenderProps.valueGetter(`${menu?.ID}`) ===
                              true && (
                              <Field
                                wrapperStyle={{ width: 300 }}
                                name={`${menu?.ID}Rights`}
                                size="small"
                                isSelectAll={true}
                                component={FormMultiSelectionFiled}
                                options={RightsList?.map((right: IRights) => {
                                  return {
                                    value: right?.ID,
                                    label: right?.Name,
                                  };
                                })}
                              />
                            )}
                          </div>
                          {menu &&
                            menu?.MenuSubList &&
                            menu?.MenuSubList.length > 0 &&
                            menu?.MenuSubList.map((submenu: any) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    width: "60%",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    minHeight: 45,
                                  }}
                                >
                                  <Field
                                    wrapperStyle={{ marginLeft: "15%" }}
                                    key={submenu?.ID}
                                    name={`${submenu?.ID}`}
                                    label={submenu?.MenuName}
                                    component={FormCheckbox}
                                    onChange={() =>
                                      handleSubMenuChange(
                                        formRenderProps,
                                        submenu?.ID,
                                        formRenderProps.valueGetter(
                                          `${submenu?.ID}`
                                        )
                                      )
                                    }
                                  />
                                  {formRenderProps.valueGetter(
                                    `${submenu?.ID}`
                                  ) === true && (
                                    <Field
                                      wrapperStyle={{ width: 300 }}
                                      // key={submenu?.ID}
                                      name={`${submenu?.ID}Rights`}
                                      isSelectAll={true}
                                      size="small"
                                      component={FormMultiSelectionFiled}
                                      options={RightsList?.map(
                                        (right: IRights) => {
                                          return {
                                            value: right?.ID,
                                            label: right?.Name,
                                          };
                                        }
                                      )}
                                    />
                                  )}
                                </div>
                              );
                            })}
                        </>
                      );
                    })}
                </GridLayoutItem>

                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginRight: 4 }}
                    onClick={() => navigate("/user")}
                  >
                    Cancel
                  </Button>
                  <ButtonWithLoading
                    label={UserID ? "Update" : "Create"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateUser;
