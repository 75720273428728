import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IAirline } from "../airlineModel";

export const getAllAirlines = createAsyncThunk(
  "Airlines/FindAllAirlines",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Airlines/FindAllAirlines`,
        {
          AirlineName: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Airlines:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createAirline = createAsyncThunk(
  "Airlines/InsertAirlines",
  async (AirlineData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Airlines/InsertAirlines`,
        AirlineData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating airline:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateAirline = createAsyncThunk(
  "Airlines/UpdateAirlines",
  async (AirlineData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Airlines/UpdateAirlines`,
        AirlineData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating airline :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteAirline = createAsyncThunk(
  "Airlines/DeleteAirlines",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Airlines/DeleteAirlines`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting airline :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAirlineByID = createAsyncThunk(
  "Airlines/FindByIDAirlines",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Airlines/FindByIDAirlines`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        AirlineName: result.AirlineName,
        AirlinePrefix: result.AirlinePrefix,
        AirlineCode: result.AirlineCode,
        Remarks: result.Remarks,
        TermsConditions: result.TermsConditions,
        CancellationPolicy: result.CancellationPolicy,
        Note: result.Note,
        ImportantInformation: result.ImportantInformation,
        ContactDetails: result.ContactDetails,
        IsActive: result.IsActive ? 1 : 2,
      } as IAirline;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching airline :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveAirlines = createAsyncThunk(
  "Airlines/FindAllActiveAirlines",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Airlines/FindAllActiveAirlines`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Airlines:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveAirline = createAsyncThunk(
  "Airlines/ActiveInActiveAirlines",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Airlines/ActiveInActiveAirlines`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive airline :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
