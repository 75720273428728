import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAirlineClass, IAirlineClassState } from "./airlineclassModel";
import {
  ActiveInactiveAirlineClass,
  createAirlineClass,
  deleteAirlineClass,
  getAirlineClassByID,
  getAllActiveAirlineClass,
  getAllAirlineClasses,
  updateAirlineClass,
} from "./services/airlineclass.services";

const initialState: IAirlineClassState = {
  loading: false,
  formLoading: false,
  error: "",
  AirlineClassID: null,
  AirlineClassList: [],
  AirlineClassDetail: {
    ClassName: "",
    IsActive: 1,
  },
};

const airlineclassSlice = createSlice({
  name: "airlineclass",
  initialState,
  reducers: {
    clearAirlineClassDetails: (state) => {
      state.AirlineClassDetail = initialState.AirlineClassDetail;
    },
    setAirlineClassID: (state, action) => {
      state.AirlineClassID = action.payload;
    },
    clearAirlineClassID: (state) => {
      state.AirlineClassID = initialState.AirlineClassID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAirlineClasses.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllAirlineClasses.fulfilled,
      (state, action: PayloadAction<IAirlineClass[]>) => {
        state.loading = false;
        state.AirlineClassList = action.payload || [];
      }
    );
    builder.addCase(getAllAirlineClasses.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AirlineClassList = [];
    });
    builder.addCase(createAirlineClass.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createAirlineClass.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createAirlineClass.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateAirlineClass.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateAirlineClass.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateAirlineClass.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteAirlineClass.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteAirlineClass.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteAirlineClass.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAirlineClassByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getAirlineClassByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.AirlineClassDetail = action.payload;
    });
    builder.addCase(getAirlineClassByID.rejected, (state, action) => {
      state.formLoading = false;
      state.AirlineClassDetail = initialState.AirlineClassDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveAirlineClass.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveAirlineClass.fulfilled,
      (state, action: PayloadAction<IAirlineClass[]>) => {
        state.loading = false;
        state.AirlineClassList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveAirlineClass.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AirlineClassList = [];
    });
    builder.addCase(ActiveInactiveAirlineClass.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveAirlineClass.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveAirlineClass.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});
export const {
  clearAirlineClassDetails,
  clearAirlineClassID,
  setAirlineClassID,
} = airlineclassSlice.actions;
export default airlineclassSlice.reducer;
