import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  createVehicleSharingPax,
  getAllVehicleSharingPaxs,
  getVehicleSharingPaxByID,
  updateVehicleSharingPax,
} from "./services/vehicleSharingPax.services";
import { clearVehicleSharingPaxDetails } from "./vehicleSharingPaxSlice";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";
import { STATUSARRAY } from "../../_contstants/common";
import FormSelectionField from "../../components/formFields/FormSelectionField";

const CreateVehicleSharingPax: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const VehicleSharingPaxDetail = useAppSelector(
    (state) => state.vehicleSharingPax.VehicleSharingPaxDetail
  );
  const VehicleSharingPaxID = useAppSelector((state) => state.muidrawer.data);
  const formLoading = useAppSelector(
    (state) => state.vehicleSharingPax.formLoading
  );

  useEffect(() => {
    if (VehicleSharingPaxID) {
      dispatch(getVehicleSharingPaxByID(VehicleSharingPaxID));
    }
  }, [VehicleSharingPaxID]);

  useEffect(() => {
    return () => {
      dispatch(clearVehicleSharingPaxDetails());
      dispatch(closed());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      if (!VehicleSharingPaxID) {
        const insertPayload = {
          Title: values.Title,
          IsActive: values?.IsActive === 1,
        };
        const response = await dispatch(createVehicleSharingPax(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearVehicleSharingPaxDetails());
          dispatch(getAllVehicleSharingPaxs());
        }
      } else {
        const editPayload = {
          ID: VehicleSharingPaxID,
          Title: values.Title,
          IsActive: values?.IsActive === 1,
        };
        const response = await dispatch(updateVehicleSharingPax(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearVehicleSharingPaxDetails());
          dispatch(getAllVehicleSharingPaxs());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={VehicleSharingPaxDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem className="master-drawer-header">
              <Typography.h4 className="m-0">
                {VehicleSharingPaxID
                  ? "Update Vehicle Sharing Pax"
                  : "Create Vehicle Sharing Pax"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="Title"
                label="Title"
                placeholder="i.e. 2 Pax"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"IsActive"}
                name={"IsActive"}
                label={"Status"}
                component={FormSelectionField}
                validator={requiredValidator}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status?.value,
                    label: status?.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "start",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={VehicleSharingPaxID ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateVehicleSharingPax;
