import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ShadowCard from "../../components/common/ShadowCard";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import FormTextField from "../../components/formFields/FormTextField";
import {
  createSector,
  getSectorByID,
  updateSector,
} from "./services/sector.services";
import { clearSectorDetails } from "./sectorSlice";
import { getAllActiveCities } from "../city/services/city.services";
import { getAllActiveStates } from "../state/services/state.services";
import { getAllActiveCountries } from "../country/services/country.services";
import { ICity } from "../city/cityModel";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { STATUSARRAY } from "../../_contstants/common";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import DocumentDetailsArray from "./DocumentDetailsArray";
import { getAllActiveDocument } from "../document/services/document.services";
import { getAllActiveRegions } from "../region/services/region.services";
import { IRegion } from "../region/regionModel";
import FormMultiSelectionFiled from "../../components/formFields/FormMultiSelectionFiled";

const CreateSector: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const SectorID = location.state?.SectorID;

  const SectorDetail = useAppSelector((state) => state.sector.SectorDetail);
  const loading = useAppSelector((state) => state.sector.loading);
  const CityList = useAppSelector((state) => state.city.CityList);
  const RegionsList = useAppSelector((state) => state?.region.RegionList);
  useEffect(() => {
    if (SectorID) {
      dispatch(getSectorByID(SectorID));
    }
  }, [SectorID]);

  useEffect(() => {
    dispatch(getAllActiveCities());
    dispatch(getAllActiveStates());
    dispatch(getAllActiveCountries());
    dispatch(getAllActiveDocument());
    dispatch(getAllActiveRegions());

    return () => {
      dispatch(clearSectorDetails());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      if (SectorID) {
        const editPayload = {
          ID: SectorID,
          RegionID: values?.RegionID ?? null,
          CityID: values?.CityID ? values?.CityID.join(",") : "",
          SectorType: values?.SectorType ?? "",
          SectorName: values?.SectorName ?? "",
          Prefix: values?.Prefix ?? "",
          VisaAmount: values?.VisaAmount ? +values?.VisaAmount : 0,
          InsuranceAmount: values?.InsuranceAmount
            ? +values?.InsuranceAmount
            : 0,
          Color: values?.Color ?? "",
          DocumentDetails: values?.DocumentDetails ?? null,
          IsActive: values?.IsActive === 1,
        };

        const response = await dispatch(updateSector(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
        }
      } else {
        const insertPayload = {
          RegionID: values?.RegionID ?? null,
          CityID: values?.CityID ? values?.CityID.join(",") : "",
          SectorType: values?.SectorType ?? "",
          SectorName: values?.SectorName ?? "",
          Prefix: values?.Prefix ?? "",
          VisaAmount: values?.VisaAmount ? +values?.VisaAmount : 0,
          InsuranceAmount: values?.InsuranceAmount
            ? +values?.InsuranceAmount
            : 0,
          Color: values?.Color ?? "",
          DocumentDetails: values?.DocumentDetails ?? null,
          IsActive: values?.IsActive === 1,
        };
        const response = await dispatch(createSector(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/sector");
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <div>
        <Form
          onSubmit={handleSubmit}
          initialValues={SectorDetail}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <ShadowCard style={{ padding: 12 }}>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ rows: 5, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4>
                      {SectorID ? "Update Sector" : "Create Sector"}
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="SectorName"
                      label="Sector"
                      placeholder="Sector Name"
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      id={"SectorType"}
                      name={"SectorType"}
                      label={"Sector Type"}
                      placeholder={"Sector Type"}
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      id={"CityID"}
                      name={"CityID"}
                      label={"City"}
                      placeholder={"City"}
                      component={FormMultiSelectionFiled}
                      options={CityList?.map((city: ICity) => {
                        return {
                          value: city?.ID,
                          label: city?.CityName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      validator={requiredValidator}
                      id={"RegionID"}
                      name={"RegionID"}
                      label={"Region"}
                      placeholder={"Select Region"}
                      component={FormSelectionField}
                      options={RegionsList?.map((region: IRegion) => {
                        return {
                          value: region?.ID,
                          label: region?.RegionName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="Prefix"
                      label="Prefix"
                      placeholder="Prefix"
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      type="number"
                      name="VisaAmount"
                      placeholder="Visa Amount"
                      label="Visa Amount"
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      type="number"
                      name="InsuranceAmount"
                      placeholder="Insurance Amount"
                      label="Insurance Amount"
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="Color"
                      label="Color"
                      placeholder="i.e. Orange"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`IsActive`}
                      label="Status"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={STATUSARRAY?.map((status: any) => {
                        return {
                          value: status?.value,
                          label: status?.label,
                        };
                      })}
                    />
                  </GridLayoutItem>
                </GridLayout>
              </ShadowCard>
              <ShadowCard style={{ padding: 12, marginTop: 10 }}>
                <GridLayout
                  // gap={{ rows: 0, cols: 10 }}
                  cols={[{ width: "100%" }]}
                >
                  <GridLayoutItem>
                    <Typography.h4>Document Details</Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{ borderBottom: "1px solid lightgray" }}
                  >
                    <FieldArray
                      formRenderProps={formRenderProps}
                      component={DocumentDetailsArray}
                      name="DocumentDetails"
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                    }}
                  >
                    <div>
                      <Button
                        type="button"
                        fillMode={"outline"}
                        themeColor={"primary"}
                        style={{ marginRight: 4 }}
                        onClick={() => navigate("/sector")}
                      >
                        Cancel
                      </Button>
                      <ButtonWithLoading
                        label={SectorID ? "Update" : "Create"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </ShadowCard>
            </FormElement>
          )}
        />
      </div>
    </>
  );
};

export default CreateSector;
