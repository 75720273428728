import React, { useEffect, useState } from "react";
import {
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import RippleButton from "../../../components/common/RippleButton";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { getAllActiveStates } from "../../state/services/state.services";
import { getAllActiveCountries } from "../../country/services/country.services";
import TouristPaxDetailsArray from "./TouristPaxDetailsArray";
import {
  clearTourBookingStep2Details,
  decreaseTourBookingActiveStep,
  increaseTourBookingActiveStep,
} from "../tourBookingSlice";
import { useLocation } from "react-router-dom";
import moment from "moment";
import {
  getByIDTourBookingStep2,
  insertTourBookingStep2,
} from "../services/tourBooking.services";
import { getAllActivePaxTypes } from "../../paxtype/services/paxType.services";
import { getAllActiveFoodOptions } from "../../foodoption/services/foodOption.services";
import { getAllActiveNationalities } from "../../nationality/services/nationality.services";

const TouristInfo: React.FC = () => {
  const location = useLocation();
  const TourBookingID = location.state?.TourBookingID;
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.tourBooking.loading);
  const TourBookingDetail = useAppSelector(
    (state) => state.tourBooking.TourBookingStep2Detail
  );

  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    dispatch(getAllActiveStates());
    dispatch(getAllActiveCountries());
    dispatch(getAllActivePaxTypes());
    dispatch(getAllActiveFoodOptions());
    dispatch(getAllActiveNationalities());

    return () => {
      dispatch(clearTourBookingStep2Details());
    };
  }, []);

  useEffect(() => {
    if (TourBookingID) {
      dispatch(getByIDTourBookingStep2(TourBookingID));
    }
  }, [TourBookingID]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [TourBookingDetail]);

  const handleSubmit = async (values: any) => {
    if (TourBookingID) {
      const insertPayload = {
        TourBookingID: TourBookingID,
        RoomPaxes: values.RoomPaxes
          ? values.RoomPaxes.map((roompax: any, index: any) => {
              return {
                RoomNo: (index + 1).toString(),
                PaxTypeID: roompax.PaxTypeID,
                TouristPaxInfo: roompax.TouristPaxInfo
                  ? roompax.TouristPaxInfo.map((touristpax: any) => {
                      return {
                        ID: touristpax.ID ? +touristpax.ID : null,
                        DOB: touristpax.DOB
                          ? moment(touristpax.DOB).format("YYYY-MM-DD")
                          : "",
                        FirstName: touristpax.FirstName
                          ? touristpax.FirstName
                          : "",
                        LastName: touristpax.LastName
                          ? touristpax.LastName
                          : "",
                        MobileNo: touristpax.MobileNo
                          ? touristpax.MobileNo
                          : "",
                        RoomNo: (index + 1).toString(),
                        DiscountAmount: touristpax.DiscountAmount
                          ? +touristpax.DiscountAmount
                          : null,
                        Amount: touristpax.Amount ? +touristpax.Amount : null,
                        Initial: touristpax.Initial ? touristpax.Initial : "",
                        ExtraCost: touristpax.ExtraCost
                          ? +touristpax.ExtraCost
                          : null,
                        IsVisa: touristpax.IsVisa ? touristpax.IsVisa : false,
                        FoodOptionID: touristpax.FoodOptionID
                          ? +touristpax.FoodOptionID
                          : null,
                        PassportNo: touristpax.PassportNo
                          ? touristpax.PassportNo
                          : "",
                        ExpiryDate: touristpax.ExpiryDate
                          ? moment(touristpax.ExpiryDate).format("YYYY-MM-DD")
                          : null,
                        NationalityID: touristpax.NationalityID
                          ? +touristpax.NationalityID
                          : null,
                        PanNo: touristpax.PanNo ? touristpax.PanNo : "",
                        AadharNo: touristpax.AadharNo
                          ? touristpax.AadharNo
                          : "",
                      };
                    })
                  : [],
              };
            })
          : [],
      };
      const response = await dispatch(insertTourBookingStep2(insertPayload));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(increaseTourBookingActiveStep());
      }
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={TourBookingDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout gap={{ rows: 0, cols: 10 }} cols={[{ width: "100%" }]}>
              <GridLayoutItem>
                <Typography.h4>{"Pax Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem style={{ marginTop: 10 }}>
                <FieldArray
                  formRenderProps={formRenderProps}
                  component={TouristPaxDetailsArray}
                  name="RoomPaxes"
                />
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  textAlign: "end",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <RippleButton
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 4 }}
                  onClick={() => dispatch(decreaseTourBookingActiveStep())}
                >
                  Cancel
                </RippleButton>
                <ButtonWithLoading
                  label={"Save"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
        </FormElement>
      )}
    />
  );
};

export default TouristInfo;
