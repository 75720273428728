export const checkAcessRights = (route: string, rightID: string) => {
  const userResponseString = localStorage.getItem("UserRightsAssign") || "[]";
  let userResponse: {
    MenuID: number;
    MenuKey: string;
    MenuName: string;
    RightsID: string;
  }[];

  try {
    userResponse = JSON.parse(userResponseString);
  } catch (error) {
    userResponse = [];
  }

  return userResponse
    ?.find((e: any) => e.MenuKey === route?.substring(1))
    ?.RightsID?.includes(rightID);
};

export const hasRights = (menuKey: string) => {
  const userResponseString = localStorage.getItem("UserRightsAssign") || "[]";
  let userResponse: {
    MenuID: number;
    MenuKey: string;
    MenuName: string;
    RightsID: string;
  }[];

  try {
    userResponse = JSON.parse(userResponseString);
  } catch (error) {
    userResponse = [];
  }

  const menu = userResponse.find((item) => item.MenuKey === menuKey);
  return menu && menu.RightsID;
};
