import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ISpecialityType } from "../specialityTypeModel";

export const getAllSpecialityTypes = createAsyncThunk(
  "SpecialityType/FindAllSpecialityType",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SpecialityType/FindAllSpecialityType`,
        {
          SpecialityType: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Speciality Types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createSpecialityType = createAsyncThunk(
  "SpecialityType/InsertSpecialityType",
  async (SpecialityTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SpecialityType/InsertSpecialityType`,
        SpecialityTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating speciality type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateSpecialityType = createAsyncThunk(
  "SpecialityType/UpdateSpecialityType",
  async (SpecialityTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SpecialityType/UpdateSpecialityType`,
        SpecialityTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating speciality type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteSpecialityType = createAsyncThunk(
  "SpecialityType/DeleteSpecialityType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SpecialityType/DeleteSpecialityType`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting speciality type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSpecialityTypeByID = createAsyncThunk(
  "SpecialityType/FindByIDSpecialityType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SpecialityType/FindByIDSpecialityType`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        SpecialityType: result.SpecialityType,
        Prefix: result.Prefix,
        IsActive: result.IsActive ? 1 : 2,
      } as ISpecialityType;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching speciality type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveSpecialityTypes = createAsyncThunk(
  "SpecialityType/FindAllActiveSpecialityType",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/SpecialityType/FindAllActiveSpecialityType`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching speciality types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveSpecialityType = createAsyncThunk(
  "SpecialityType/ActiveInActiveSpecialityType",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SpecialityType/ActiveInActiveSpecialityType`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive speciality type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
