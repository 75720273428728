import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActiveInactiveVendors,
  createVendors,
  deleteVendors,
  getAllActiveVendors,
  getAllVendors,
  getVendorsByID,
  updateVendors,
} from "./services/vendors.services";
import { IVendors, IVendorsState } from "./vendorsModel";

const initialState: IVendorsState = {
  loading: false,
  error: "",
  VendorsID: null,
  VendorDetailsDeleteIndex: -1,
  VendorsList: [],
  VendorsDetail: {
    ID: null,
    VendorTypeID: null,
    VendorName: "",
    Address: "",
    CityID: null,
    StateID: null,
    CountryID: null,
    PinCode: null,
    PANNo: "",
    GSTNo: "",
    VendorDetails: [],
    IsActive: 1,
  },
};

const vendorsSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    clearVendorsDetails: (state) => {
      state.VendorsDetail = initialState.VendorsDetail;
    },
    setVendorsID: (state, action) => {
      state.VendorsID = action.payload;
    },
    clearVendorsID: (state) => {
      state.VendorsID = initialState.VendorsID;
    },
    setVendorDetailsDeleteIndex: (state, action) => {
      state.VendorDetailsDeleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllVendors.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllVendors.fulfilled,
      (state, action: PayloadAction<IVendors[]>) => {
        state.loading = false;
        state.VendorsList = action.payload || [];
      }
    );
    builder.addCase(getAllVendors.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.VendorsList = [];
    });
    builder.addCase(createVendors.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createVendors.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createVendors.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateVendors.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateVendors.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateVendors.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteVendors.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteVendors.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteVendors.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getVendorsByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getVendorsByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.VendorsDetail = action.payload;
    });
    builder.addCase(getVendorsByID.rejected, (state, action) => {
      state.loading = false;
      state.VendorsDetail = initialState.VendorsDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveVendors.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveVendors.fulfilled,
      (state, action: PayloadAction<IVendors[]>) => {
        state.loading = false;
        state.VendorsList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveVendors.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.VendorsList = [];
    });
    builder.addCase(ActiveInactiveVendors.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveVendors.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveVendors.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearVendorsDetails,
  clearVendorsID,
  setVendorsID,
  setVendorDetailsDeleteIndex,
} = vendorsSlice.actions;
export default vendorsSlice.reducer;
