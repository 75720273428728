import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IState } from "../stateModel";

export const getAllStates = createAsyncThunk("State/FindAllState", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/State/FindAllState`,
      {
        StateName: "",
      }
    );
    return response.data?.Data;
  } catch (error: any) {
    // ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching states:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createState = createAsyncThunk(
  "State/InsertState",
  async (StateData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/State/InsertState`,
        StateData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating state:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateState = createAsyncThunk(
  "State/UpdateState",
  async (StateData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/State/UpdateState`,
        StateData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating state:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteState = createAsyncThunk(
  "State/DeleteState",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/State/DeleteState`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting state:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getStateByID = createAsyncThunk(
  "State/FindByIDState",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/State/FindByIDState`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        CountryID: result.CountryID,
        StateName: result.StateName,
        IsActive: result.IsActive ? 1 : 2,
      } as IState;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching state:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveStates = createAsyncThunk(
  "State/FindAllActiveState",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/State/FindAllActiveState`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching states:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const stateActiveInactive = createAsyncThunk(
  "State/ActiveInActiveState",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/State/ActiveInActiveState`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive state:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
