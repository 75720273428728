import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import React, { useEffect } from "react";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import IconButton from "../../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import FormTextField from "../../../components/formFields/FormTextField";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { setTourBookingDeleteIndex } from "../tourBookingSlice";
import { getAllActivePaxTypes } from "../../paxtype/services/paxType.services";
import { IPaxType } from "../../paxtype/paxTypeModel";
import { requiredValidator } from "../../../components/formFields/CommonValidator";

const PaxDetailsArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const paxDeleteIndex = useAppSelector(
    (state) => state.tourBooking.deleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const PaxTypesList = useAppSelector((state) => state.paxType.PaxTypeList);

  useEffect(() => {
    dispatch(getAllActivePaxTypes());
  }, []);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("paxDeleteDialog"));
    dispatch(setTourBookingDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setTourBookingDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        PaxTypeID: null,
        NoOfRoom: null,
      },
    });
  }, [fieldArrayRenderProps]);

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {!fieldArrayRenderProps.value ? pushElementInarray() : null}
      <GridLayoutItem style={{ marginBottom: 10 }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Pax
        </Button>
      </GridLayoutItem>
      {dialogName === "paxDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Pax Details"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(paxDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((PaxTypeList: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card">
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem>
                  <Field
                    name={`PaxTypeList.${index}.PaxTypeID`}
                    label="Pax Type"
                    component={FormSelectionField}
                    options={PaxTypesList?.map((paxtype: IPaxType) => {
                      return {
                        value: paxtype?.ID,
                        label: paxtype?.PaxType,
                      };
                    })}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`PaxTypeList.${index}.NoOfRoom`}
                    label="No Of Room"
                    type="number"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    textAlign: "right",
                    marginTop: 38,
                  }}
                >
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{ height: 24, width: 24 }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
    </GridLayout>
  );
};

export default PaxDetailsArray;
