import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  createFoodOption,
  getAllFoodOptions,
  getFoodOptionByID,
  updateFoodOption,
} from "./services/foodOption.services";
import { clearFoodOptionDetails } from "./foodOptionSlice";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { STATUSARRAY } from "../../_contstants/common";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";

const CreateFoodOption: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const FoodOptionDetail = useAppSelector(
    (state) => state.foodOption.FoodOptionDetail
  );
  const FoodOptionID = useAppSelector((state) => state.muidrawer.data);
  const formLoading = useAppSelector((state) => state.foodOption.formLoading);

  useEffect(() => {
    if (FoodOptionID) {
      dispatch(getFoodOptionByID(FoodOptionID));
    }
  }, [FoodOptionID]);

  useEffect(() => {
    return () => {
      dispatch(clearFoodOptionDetails());
      dispatch(closed());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      if (!FoodOptionID) {
        const insertPayload = {
          Name: values.Name ? values.Name : "",
          IsActive: values.IsActive === 1,
        };
        const response = await dispatch(createFoodOption(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearFoodOptionDetails());
          dispatch(getAllFoodOptions());
        }
      } else {
        const editPayload = {
          ID: FoodOptionID,
          Name: values.Name ? values.Name : "",
          IsActive: values.IsActive === 1,
        };
        const response = await dispatch(updateFoodOption(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearFoodOptionDetails());
          dispatch(getAllFoodOptions());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={FoodOptionDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem className="master-drawer-header">
              <Typography.h4 className="m-0">
                {FoodOptionID ? "Update Food Option" : "Create Food Option"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="Name"
                label="Food Option"
                placeholder="I.e. Regular"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name={`IsActive`}
                label="Status"
                component={FormSelectionField}
                validator={requiredValidator}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status?.value,
                    label: status?.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              style={{
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={!FoodOptionID ? "Create" : "Update"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 5 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateFoodOption;
