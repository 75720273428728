import * as React from "react";
import {
  MultiSelect,
  MultiSelectFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { filterBy } from "@progress/kendo-data-query";
import { Error, Hint, Label } from "@progress/kendo-react-labels";
import { CgDanger } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
const delay = 300;

const FormMultiSelectionFiled = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    size = "medium",
    disabled,
    hint,
    value,
    options = [],
    onChange,
    isSelectAll = false,
    isAddNew = false,
    addNewLink = "",
    textField = "label",
    wrapperStyle,
    name,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef<any>(null);
  const navigate = useNavigate();
  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  const [data, setData] = React.useState(options);
  const [loading, setLoading] = React.useState(false);
  // eslint-disable-next-line no-undef
  const timeout = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    let updatedData = options;
    if (isSelectAll) {
      updatedData = [
        { label: "Select All", value: "selectall" },
        ...updatedData,
      ];
    }
    if (isAddNew) {
      updatedData = [{ label: "Add New", value: "addnew" }, ...updatedData];
    }
    setData(updatedData);
  }, [options, isSelectAll, isAddNew]);

  const handleFilterChange = (event: MultiSelectFilterChangeEvent) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      setData(filterBy(options?.slice(), event.filter));
      setLoading(false);
    }, delay);
    setLoading(true);
  };

  const handleSelect = (event: any) => {
    const selectedValuesArray = event.target.value;
    const values = selectedValuesArray.map((option: any) => option.value);
    const allValues = options.map((option: any) => option.value);
    if (values && values.includes("selectall")) {
      onChange({ value: allValues });
    } else if (values && values.includes("addnew") && addNewLink) {
      navigate(addNewLink);
    } else {
      onChange({ value: values });
    }
  };

  let initialValue = options?.filter((option: any) => {
    return (value || []).includes(option.value);
  });

  return (
    <>
      <FieldWrapper style={wrapperStyle}>
        <Label
          // editorValue={value}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          // label={label}
          //   labelClassName="k-form-label"
        >
          {label}
        </Label>
        <MultiSelect
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
          // data={
          //   data?.length > 0 && isSelectAll
          //     ? [{ label: "Select All", value: "selectall" }, ...data]
          //     : data
          // }
          data={data}
          name={name}
          value={Array.isArray(initialValue) ? initialValue : []}
          filterable={true}
          onFilterChange={handleFilterChange}
          loading={loading}
          size={size}
          textField={textField}
          onChange={handleSelect}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error className="d-flex justify-content-end" id={errorId}>
            <span
              className="d-flex align-items-center"
              style={{
                background: "#DF4957",
                color: "white",
                padding: "1px 8px 1px 5px",
                borderRadius: 10,
                gap: 5,
              }}
            >
              <CgDanger style={{marginBottom:2}} /> {validationMessage}
            </span>
          </Error>
        )}
      </FieldWrapper>
    </>
  );
};

export default FormMultiSelectionFiled;
