import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { setVendorDetailsDeleteIndex } from "./vendorsSlice";
import FormTextField from "../../components/formFields/FormTextField";

const VendorDetailsArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const VendorsID = location.state?.VendorsID;
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const VendorDetailsDeleteIndex = useAppSelector(
    (state) => state.vendor.VendorDetailsDeleteIndex
  );

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("vendorDetailsDeleteDialog"));
    dispatch(setVendorDetailsDeleteIndex(index));
  };

  useEffect(() => {
    if (!VendorsID || fieldArrayRenderProps?.value?.length < 1) {
      pushElementInarray();
    }
  }, []);

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setVendorDetailsDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        Name: "",
        MobileNo: "",
        WhatsappNo: "",
        EmailId: "",
        Destignation: "",
      },
    });
  }, [fieldArrayRenderProps]);

  return (
    <>
      <GridLayout>
        <GridLayoutItem
          className="bg-dark text-white fw-600"
          style={{ width: "100%" }}
        >
          <GridLayout
            style={{
              marginRight: 60,
              padding: 10,
            }}
            gap={{ rows: 10, cols: 10 }}
            cols={[
              { width: "4%" },
              { width: "18%" },
              { width: "18%" },
              { width: "18%" },
              { width: "18%" },
              { width: "18%" },
              { width: "6%" },
            ]}
          >
            <GridLayoutItem>SR No</GridLayoutItem>
            <GridLayoutItem>Name</GridLayoutItem>
            <GridLayoutItem>Mobile No</GridLayoutItem>
            <GridLayoutItem>Whatsapp No</GridLayoutItem>
            <GridLayoutItem>Email Id</GridLayoutItem>
            <GridLayoutItem>Destignation</GridLayoutItem>
            <GridLayoutItem>Action</GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
        {dialogName === "vendorDetailsDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <DialogCloseButton themeColor="error" />
                  <Button
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() => handleDeleteAction(VendorDetailsDeleteIndex)}
                    type="button"
                  >
                    Delete
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map((_: any, index: number) => (
            <GridLayoutItem key={index} style={{ width: "100%" }}>
              <GridLayout
                style={{ marginRight: 60, padding: 10 }}
                gap={{ rows: 10, cols: 10 }}
                cols={[
                  { width: "4%" },
                  { width: "18%" },
                  { width: "18%" },
                  { width: "18%" },
                  { width: "18%" },
                  { width: "18%" },
                  { width: "6%" },
                ]}
              >
                <GridLayoutItem>
                  <h5>{index + 1}</h5>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"Name"}
                    wrapperStyle={{ margin: 0 }}
                    name={`VendorDetails.${index}.Name`}
                    component={FormTextField}
                    placeholder="Name"
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"MobileNo"}
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`VendorDetails.${index}.MobileNo`}
                    placeholder="Mobile"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"WhatsappNo"}
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`VendorDetails.${index}.WhatsappNo`}
                    placeholder="Whatsapp"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"EmailId"}
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`VendorDetails.${index}.EmailId`}
                    placeholder="Email ID"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"Destignation"}
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`VendorDetails.${index}.Destignation`}
                    placeholder="Destignation"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    position: "relative",
                  }}
                >
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{
                      position: "absolute",
                      height: 32,
                      width: 32,
                      top: 0,
                      right: 0,
                    }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
              </GridLayout>
            </GridLayoutItem>
          ))}
      </GridLayout>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{ marginTop: 10, borderTop: "1px solid lightgray" }}
        >
          <GridLayout
            style={{ marginRight: 45, padding: 10 }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "20%" },
              { width: "20%" },
              { width: "20%" },
              { width: "20%" },
              { width: "20%" },
            ]}
          >
            <GridLayoutItem className="d-flex align-items-end">
              <Button
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={pushElementInarray}
                type="button"
              >
                Add Item
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default VendorDetailsArray;
