import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ShadowCard from "../../components/common/ShadowCard";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import FormTextField from "../../components/formFields/FormTextField";
import {
  BankDetailsImageUpload,
  createBank,
  getBankByID,
  updateBank,
} from "./services/bank.services";
import { clearBankDetails } from "./bankSlice";

import FormSelectionField from "../../components/formFields/FormSelectionField";
import { getAllActiveCompanies } from "../company/services/company.services";
import { ICompany } from "../company/companyModel";

import { STATUSARRAY } from "../../_contstants/common";
import FormImageUpload from "../../components/formFields/FormImageUpload";

const CreateBank: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const BankID = location.state?.BankID;
  const BankDetail = useAppSelector((state) => state.bank.BankDetail);
  const loading = useAppSelector((state) => state.bank.loading);
  const CompanyList = useAppSelector((state) => state.company.CompanyList);

  useEffect(() => {
    if (BankID) {
      dispatch(getBankByID(BankID));
    }
  }, [BankID]);

  useEffect(() => {
    dispatch(getAllActiveCompanies());
    return () => {
      dispatch(clearBankDetails());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    const formData = {
      CompanyID: values.CompanyID ? +values.CompanyID : null,
      BankName: values.BankName ?? "",
      Branch: values.Branch ?? "",
      Address: values.Address ?? "",
      AccountNo: values.AccountNo ?? "",
      IFSCCode: values.IFSCCode ?? "",
      IsActive: values.IsActive === 1,
    };
    const ImageData = new FormData();
    const QRCodeImage =
      values?.QRCodeImage && typeof values?.QRCodeImage !== "string"
        ? values.QRCodeImage[0].getRawFile()
        : "";
    const CancelledChequeImage =
      values?.CancelledChequeImage &&
      typeof values?.CancelledChequeImage !== "string"
        ? values.CancelledChequeImage[0].getRawFile()
        : "";
    try {
      if (BankID) {
        const editPayload = {
          ...formData,
          ID: BankID,
        };

        const response = await dispatch(updateBank(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          if (
            (values?.QRCodeImage && typeof values?.QRCodeImage !== "string") ||
            (values?.CancelledChequeImage &&
              typeof values?.CancelledChequeImage !== "string")
          ) {
            ImageData.append("QRCodeImage", QRCodeImage);
            ImageData.append("CancelledChequeImage", CancelledChequeImage);
            ImageData.append("ID", BankID);
            const imageResponse = await dispatch(
              BankDetailsImageUpload(ImageData)
            );
            if (imageResponse?.meta?.requestStatus === "fulfilled") {
              navigate("/bank");
            } else {
              navigate("/bank");
            }
          } else {
            navigate("/bank");
          }
        }
      } else {
        const response = await dispatch(createBank(formData));
        if (response?.meta?.requestStatus === "fulfilled") {
          if (
            ((values?.QRCodeImage && typeof values?.QRCodeImage !== "string") ||
              (values?.CancelledChequeImage &&
                typeof values?.CancelledChequeImage !== "string")) &&
            response?.payload?.Data?.ID
          ) {
            ImageData.append("QRCodeImage", QRCodeImage);
            ImageData.append("CancelledChequeImage", CancelledChequeImage);
            ImageData.append("ID", response?.payload?.Data?.ID);
            const imageResponse = await dispatch(
              BankDetailsImageUpload(ImageData)
            );
            if (imageResponse?.meta?.requestStatus === "fulfilled") {
              navigate("/bank");
            } else {
              navigate("/bank");
            }
          } else {
            navigate("/bank");
          }
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <div>
        <Form
          onSubmit={handleSubmit}
          initialValues={BankDetail}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <ShadowCard style={{ padding: 12 }}>
                <GridLayout
                  style={{ marginRight: 40 }}
                  gap={{ rows: 5, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  <GridLayoutItem colSpan={4}>
                    <Typography.h4>
                      {BankID ? "Update Bank" : "Create Bank"}
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="BankName"
                      label="Bank"
                      placeholder="i.e. Bank Of Baroda"
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      id={"CompanyID"}
                      name={"CompanyID"}
                      label={"Company"}
                      placeholder="Select Company"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={CompanyList?.map((company: ICompany) => {
                        return {
                          value: company?.ID,
                          label: company?.CompanyName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="Branch"
                      label="Branch"
                      placeholder="i.e. Vastral"
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="Address"
                      label="Address"
                      placeholder="Address"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="AccountNo"
                      label="Account No"
                      type="number"
                      validator={requiredValidator}
                      placeholder="i.e. 123456789012"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="IFSCCode"
                      label="IFSC"
                      placeholder="i.e. ABCZ0001234"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`IsActive`}
                      label="Status"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={STATUSARRAY?.map((status: any) => {
                        return {
                          value: status?.value,
                          label: status?.label,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem></GridLayoutItem>
                  <GridLayoutItem style={{ marginTop: 20 }}>
                    <Typography.h6>{"QR Image"}</Typography.h6>
                    <Field
                      justifyContent="start"
                      name={`QRCodeImage`}
                      label="QR Image"
                      type="file"
                      accept="image/*"
                      alternateName="QR Image"
                      component={FormImageUpload}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={3}></GridLayoutItem>
                  <GridLayoutItem style={{ marginTop: 20 }}>
                    <Typography.h6>{"Cancel Cheque Image"}</Typography.h6>
                    <Field
                      justifyContent="start"
                      name={`CancelledChequeImage`}
                      label="Cancel Cheque Image"
                      type="file"
                      accept="image/*"
                      alternateName="Cancel Cheque Image"
                      component={FormImageUpload}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={4}
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                    }}
                  >
                    <div>
                      <Button
                        type="button"
                        fillMode={"outline"}
                        themeColor={"primary"}
                        style={{ marginRight: 4 }}
                        onClick={() => navigate("/bank")}
                      >
                        Cancel
                      </Button>
                      <ButtonWithLoading
                        label={BankID ? "Update" : "Create"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </ShadowCard>
            </FormElement>
          )}
        />
      </div>
    </>
  );
};

export default CreateBank;
