import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IVendorType, IVendorTypeInitialState } from "./vendorTypeModel";
import {
  ActiveInactiveVendorType,
  createVendorType,
  deleteVendorType,
  getAllActiveVendorTypes,
  getAllVendorTypes,
  getVendorTypeByID,
  updateVendorType,
} from "./services/vendorType.services";

const initialState: IVendorTypeInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  VendorTypeList: [],
  VendorTypeID: null,
  VendorTypeDetail: {
    VendorType: "",
    IsActive: 1,
  },
};

const vendorTypeSlice = createSlice({
  name: "vendorType",
  initialState,
  reducers: {
    clearVendorTypeDetails: (state) => {
      state.VendorTypeDetail = initialState.VendorTypeDetail;
    },
    setVendorTypeID: (state, action) => {
      state.VendorTypeID = action.payload;
    },
    clearVendorTypeID: (state) => {
      state.VendorTypeID = initialState.VendorTypeID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllVendorTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllVendorTypes.fulfilled,
      (state, action: PayloadAction<IVendorType[]>) => {
        state.loading = false;
        state.VendorTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllVendorTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.VendorTypeList = [];
    });
    builder.addCase(createVendorType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createVendorType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createVendorType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateVendorType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateVendorType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateVendorType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteVendorType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteVendorType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteVendorType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getVendorTypeByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getVendorTypeByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.VendorTypeDetail = action.payload;
    });
    builder.addCase(getVendorTypeByID.rejected, (state, action) => {
      state.formLoading = false;
      state.VendorTypeDetail = initialState.VendorTypeDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveVendorTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveVendorTypes.fulfilled,
      (state, action: PayloadAction<IVendorType[]>) => {
        state.loading = false;
        state.VendorTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveVendorTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.VendorTypeList = [];
    });
    builder.addCase(ActiveInactiveVendorType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveVendorType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveVendorType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearVendorTypeDetails, clearVendorTypeID, setVendorTypeID } =
  vendorTypeSlice.actions;
export default vendorTypeSlice.reducer;
