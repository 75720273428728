import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IAirport } from "../airportModel";

export const getAllAirports = createAsyncThunk(
  "Airports/FindAllAirports",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Airports/FindAllAirports`,
        {
          AirportName: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Airports:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createAirport = createAsyncThunk(
  "Airports/InsertAirports",
  async (AirportData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Airports/InsertAirports`,
        AirportData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating airport:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateAirport = createAsyncThunk(
  "Airports/UpdateAirports",
  async (AirportData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Airports/UpdateAirports`,
        AirportData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating airport:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteAirport = createAsyncThunk(
  "Airports/DeleteAirports",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Airports/DeleteAirports`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting airport:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAirportByID = createAsyncThunk(
  "Airports/FindByIDAirports",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Airports/FindByIDAirports`,
        { ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        AirportName: result.AirportName,
        AirportCode: result.AirportCode,
        IsActive: result.IsActive ? 1 : 2,
      } as IAirport;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching airport:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveAirports = createAsyncThunk(
  "Airports/FindAllActiveAirports",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Airports/FindAllActiveAirports`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Airports:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveAirport = createAsyncThunk(
  "Airports/ActiveInActiveAirports",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Airports/ActiveInActiveAirports`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive airport:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
